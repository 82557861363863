import React, { useRef, useEffect, useState } from 'react';

import { Table, Button } from "react-bootstrap";
import { useForm } from 'react-hook-form';
import { format } from 'date-fns';
import Swal from 'sweetalert2';
import SweetAlert from 'sweetalert';

import {
  TextField,
  useMediaQuery,
  Grid,
  InputAdornment,
  Select,
} from "@material-ui/core";
import SubjectIcon from "@material-ui/icons/Subject";
import SearchIcon from "@material-ui/icons/Search";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from '@material-ui/icons/Delete';
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import List from '@material-ui/core/List';
import ButtonMaterial from '@material-ui/core/Button';

import {
  faTrashAlt,
  faChevronRight,
  faChevronLeft,
  faTruck,
  faMobile,
  faHandHoldingBox,
  faCheck,
} from "@fortawesome/pro-light-svg-icons";
import { faCamera } from "@fortawesome/pro-solid-svg-icons";

import Domain from '../../services/Domain';
import Categorizacao from '../../services/Categorizacao';
import ShowcaseService from '../../services/Showcase';
import AnuncioService from '../../services/Anuncios';

import ModalDialog from '../../components/ModalDialog';
import GridProduct from '../../components/GridProduct';
import ProductCard from '../../components/ProductCard';

function Showcase() {
  const matches = useMediaQuery("(min-width:600px)");

  const refModalAddShowcase = useRef();
  const refModalDialogCategory = useRef();
  const refModalDialogSelectProduct = useRef();
  const showcase = new ShowcaseService();

  const [showcases, setShowcases] = useState();
  const [selectedShowcase, setselectedShowcase] = useState();
  const [selectedImages, setselecImagens] = useState([0, 0, 0, 0]);
  const [imagens, setImanges] = useState();
  const [category, setCategory] = useState("");
  const [brands, setBrands] = useState([]);
  const [brand, setBrand] = useState({ nome: "" });
  const [conditions, setConditions] = useState([]);
  const [condition, setCondition] = useState({ nome: "" });
  const [listSelectedCategorization, setListSelectedCategorization] = useState([]);
  const [listActiveCategorization, setListActiveCategorization] = useState([]);
  const [allCategorizationLabel, setAllCategorizationLabel] = useState("");
  const [listCategorization, setListCategorization] = useState([]);
  const [layouts, setLayouts] = useState();
  const [tipo, setTipo] = useState();
  const [tipoFiltro, setTipoFiltro] = useState("filters");
  const [check, setCheck] = useState({ pause_adjustment: true });
  const [isEdit, setIsEdit] = useState(false);
  const [filter, setFilter] = useState();
  const [searchAds, setSearchAds] = useState();
  const [selectedAds, setSelectedAds] = useState();
  const [limit, setLimit] = useState(8);
  const [searchKey, setSearchKey] = useState("");
  const [deliveryType, setDeliveryType] = useState();
  const [points, setPoints] = useState();
  const [price, setPrice] = useState();

  const { handleSubmit, register, errors, reset, setValue } = useForm();
  const { handleSubmit: handleSubmitFilter, register: registerFilter } = useForm();
  const { handleSubmit: handleSubmitSearchFilter, register: registerSearchFilter, reset: resetSearchFilter } = useForm();


  const openAddShowcaseModal = () => {
    setIsEdit(false);
    reset({});
    setBrand([]);
    setCondition([]);
    setCheck({ pause_adjustment: true });
    setListSelectedCategorization([]);
    setLayouts();
    setImanges([]);
    setTipoFiltro("filters");
    setLimit(8);
    setselecImagens([0, 0, 0, 0]);
    setSelectedAds();

    refModalAddShowcase.current.openModal();
  }

  const openEditShowcaseModal = (showcaseData) => {
    Swal.fire('Carregando Vitrine... Aguarde');
    Swal.showLoading();
    setIsEdit(true);
    setselectedShowcase(showcaseData._id);

    showcase.getOneShowcases(showcaseData._id).then((response) => {
      const data = response.data.payload;

      setValue("bannerOrder", data.ordination);
      setValue("titulo", data.title);
      setValue("subtitulo", data.subtitle);
      setValue("tipo", data.tipo);
      setValue("filterby", data.filters.filterbyKey);
      setValue("filterType", data.filter_type);
      setValue("order", data.filters.orderBy);
      setValue("pontosMinimos", data.filters.points.split('-')[0]);
      setValue("pontosMaximos", data.filters.points.split('-')[1]);
      setValue("initialDate", format(new Date(data.started_date), 'yyyy-MM-dd'))
      setValue("endDate", format(new Date(data.expired_date), 'yyyy-MM-dd'));
      setValue("operador_logistico", data.filters.operador_logistico);
      setValue("entrega_maos", data.filters.entrega_maos);
      setBrand(data.filters.brand[0]);
      setCondition(data.filters.condition[0]);
      setTipoFiltro(data.filter_type);
      setSelectedAds(data.anuncios)
      setTipo(data.tipo);
      setCheck({ pause_adjustment: data.disabled });
      setLimit(8);
      setListSelectedCategorization(
        data.filters.categoryList.map((cat) => {
          return { categorization: cat };
        })
      );
      handleShowImanges(data.ordination);

      data.banners.map((foto, index) =>
        showcase.loadImageFile(foto).then((file) => {
          setSelectedImages({ target: { files: [file] } }, index);
        })
      );
      Swal.close();
      refModalAddShowcase.current.openModal();
    }).catch(err => {
      Swal.fire({
        type: "info",
        html: showcase.formatErrorMessage(err.response),
        title: "",
      });
    });
  }

  const deleteShowcase = (showcaseData) => {

    SweetAlert({
      title: "Deletar Vitrine ?",
      text: `Ao clicar em confirmar, a vitrine será excluida permanentemente`,
      icon: "warning",
      buttons: ["Cancelar", "Confirmar"],
      dangerMode: true,
    }).then(function (isConfirm) {
      if (isConfirm) {
        showcase.deleteShowcase(showcaseData._id).then((response) => {
          loadShowcase(filter);
          SweetAlert({
            title: "Tudo pronto!",
            text: `Vitrine excluida`,
            icon: "success",
            buttons: [false, "Ok"],
            dangerMode: true,
          })
        }).catch((error) => {
          Swal.fire({
            type: "info",
            html: showcase.formatErrorMessage(error.response, 'DELS'),
            title: "",
          });

        });
      }
    });
  }

  const openCategorizationModal = () => {
    setListActiveCategorization(listCategorization);
    setListSelectedCategorization([]);
    setAllCategorizationLabel("");

    refModalDialogCategory.current.openModal();
  };

  const openSelectProductModal = () => {
    setBrand([]);
    setCondition([]);
    setListSelectedCategorization([]);

    searchAnuncio({ limit: limit, key: "" })
    refModalDialogSelectProduct.current.openModal();
  }

  const onFilter = (data) => {
    setFilter(data.filter);
    loadShowcase(data.filter);
  }

  const loadInfo = () => {
    const categorization = new Categorizacao();
    const domain = new Domain();

    categorization
      .list()
      .then((response) => {
        if (response.status === 200) {
          setListCategorization(response.data.payload);
          setListActiveCategorization(response.data.payload);
        }
      })
      .catch((error) =>
        categorization.formatErrorMessage(error.response, 'CALICT'),
      );

    domain
      .listBrands()
      .then((response) => {
        if (response.status === 200) {
          setBrands(response.data.payload);
        }
      })
      .catch((error) =>
        domain.formatErrorMessage(error.response, 'CALIBDO'),
      );

    domain
      .listConditions()
      .then((response) => {
        if (response.status === 200) {
          setConditions(response.data.payload);
        }
      })
      .catch((error) =>
        domain.formatErrorMessage(error.response, 'CALICDO'),
      );
  };

  const onSelectCategorization = (categorizationlist) => {
    let selectedCategorizations = categorizationlist
      ? categorizationlist
      : listSelectedCategorization;

    let categorizationIdsSelected = selectedCategorizations.map(
      (selectedCategorization) => {
        return selectedCategorization.categorization._id;
      }
    );
  }

  const hasChindren = (categorization) => {
    if (categorization.categorias && categorization.categorias.length > 0) {
      return true;
    } else if (
      categorization.tipos_produtos &&
      categorization.tipos_produtos.length > 0
    ) {
      return true;
    } else if (categorization.produtos && categorization.produtos.length > 0) {
      return true;
    }

    return false;
  };

  const setSelectedImages = (e, index) => {
    var reader = new FileReader();
    var file = e.target.files[0];
    reader.readAsDataURL(file);

    reader.onloadend = function (e) {
      var image = new Image();
      image.src = e.target.result;
      image.onload = function () {
        if (this.width < this.height) {
          selectedImages.splice(index, 1, { show: reader.result, file: file, orientation: '_portrait' });
        } else if (this.width > this.height) {
          selectedImages.splice(index, 1, { show: reader.result, file: file, orientation: '_landscape' });
        } else {
          selectedImages.splice(index, 1, { show: reader.result, file: file, orientation: '_square' });
        }
        setImanges([...selectedImages]);
      }
    }
  };

  const removeImage = (e, index) => {
    selectedImages.splice(index, 1, 0);
    setImanges([...selectedImages]);
  };

  const filterOptions = createFilterOptions({
    matchFrom: 'start'
  });

  const handleCondition = (e, condition) => {
    setCondition(condition);
  };

  const handleBrand = (e, brand) => {
    setBrand(brand);
  };

  const handleChangeCheckbox = (event) => {
    const { target } = event;
    setCheck({ ...check, [target.name]: target.checked });
  };

  const handleShowImanges = (value) => {
    const array = [{ layout: 'twoBanners', quantity: 2 }, { layout: 'threeBaners', quantity: 3 }, , { layout: 'oneBaners', quantity: 1 }]
    const filterResult = array.filter(item => item.layout === value)

    if (filterResult.length > 0) {
      return setLayouts(new Array(filterResult[0].quantity).fill(value));
    }
  }

  const handleShowTipo = (value) => {
    if (value === 'celular') {
      handleShowImanges('oneBaners')
    } else {
      setLayouts();
    }
    return setTipo(value);
  }

  const handleCategorizationBack = (oldCategorizationList, index) => {
    const listSelected = listSelectedCategorization.slice(0, index);
    setListSelectedCategorization(listSelected);

    setListActiveCategorization(oldCategorizationList);
  };

  const handleCategorization = (categorization) => {
    setCategory(categorization.nome);

    let listSelected;
    if (categorization.categorias && categorization.categorias.length > 0) {
      setListActiveCategorization(categorization.categorias);

      listSelected = listSelectedCategorization;
      listSelected.push({
        oldCategorizationList: listActiveCategorization,
        categorization: categorization,
      });
      setListSelectedCategorization(listSelected);
    } else if (
      categorization.tipos_produtos &&
      categorization.tipos_produtos.length > 0
    ) {
      setListActiveCategorization(categorization.tipos_produtos);

      listSelected = listSelectedCategorization;
      listSelected.push({
        oldCategorizationList: listActiveCategorization,
        categorization: categorization,
      });
      setListSelectedCategorization(listSelected);
    } else if (categorization.produtos && categorization.produtos.length > 0) {
      setListActiveCategorization(categorization.produtos);

      listSelected = listSelectedCategorization;
      listSelected.push({
        oldCategorizationList: listActiveCategorization,
        categorization: categorization,
      });
      setListSelectedCategorization(listSelected);
    } else {
      listSelected = listSelectedCategorization;
      const lastIndex = listSelected.length > 0 ? listSelected.length - 1 : 0;

      if (listSelected[lastIndex] && listSelected[lastIndex].lastLevel) {
        listSelected[lastIndex] = {
          oldCategorizationList: listActiveCategorization,
          categorization: categorization,
          lastLevel: true,
        };
        setListSelectedCategorization(listSelected);
      } else {
        listSelected.push({
          oldCategorizationList: listActiveCategorization,
          categorization: categorization,
          lastLevel: true,
        });
        setListSelectedCategorization(listSelected);
      }
    }
  };

  const handleAddShowcase = (data) => {
    data.aplicativo_entrega = false;
    data.initialDate = new Date(data.initialDate);
    data.endDate = new Date(data.endDate);
    let selectedBrand;

    if (brand && brand.nome !== data.brand) {
      selectedBrand = { nome: data.brand };
      setBrand({ nome: data.brand })
    } else if (brand) {
      selectedBrand = brand;
    } else {
      selectedBrand = { nome: data.brand };
      setBrand({ nome: data.brand })
    }

    const formatedData = {
      selectedImages: imagens,
      title: data.titulo,
      subtitle: data.subtitulo,
      tipo: data.tipo,
      filter_type: data.filterType,
      filterbyKey: data.filterby,
      condition,
      listSelectedCategorization,
      brand: selectedBrand,
      bannerOrder: data.tipo === 'desktop' ? data.bannerOrder : 'oneBaners',
      order: data.order,
      initialDate: data.initialDate,
      endDate: data.endDate,
      products: selectedAds,
      disabled: check.pause_adjustment,
      pontosMinimos: data.pontosMinimos,
      pontosMaximos: data.pontosMaximos,
      deliveryTypes: {
        operador_logistico: data.operador_logistico,
        aplicativo_entrega: data.aplicativo_entrega,
        entrega_maos: data.entrega_maos,
      },
    };

    if (isEdit) {
      formatedData._id = selectedShowcase;
    }

    const formDataSc = new FormData();
    formDataSc.append("showcase", JSON.stringify(formatedData));

    imagens.map((image, index) => {
      if (image !== 0) {
        formDataSc.append("image" + (index + 1), image.file);
      }
    });

    if (isEdit) {
      Swal.fire('Salvando Vitrine... Aguarde');
      Swal.showLoading();
      showcase.updateShowcase(formDataSc).then((response) => {
        refModalAddShowcase.current.closeModal();
        reset();
        SweetAlert({
          title: "",
          text: response.data.message ? response.data.message : 'Vitrine editada com sucesso!',
          icon: "success",
        }).then(() => {
          loadShowcase(filter);
        });
      });
    } else {
      Swal.fire('Criando Vitrine... Aguarde');
      Swal.showLoading();
      if (check.pause_adjustment) {
        SweetAlert({
          title: "Vitrine",
          text: `Lembrando! Sua vitrine está sendo criada desativada`,
          icon: "info",
          buttons: [, "Confirmar"],
        }).then(function (isConfirm) {
          if (isConfirm) {
            showcase.createShowcase(formDataSc).then((response) => {
              loadShowcase(filter);
              SweetAlert({
                title: "Vitrine Cadastrada",
                text: response.data.message ? response.data.message : 'Vitrine incluida com sucesso!',
                icon: "success",
                buttons: ["Fechar", "Criar Novo com mesmos Filtros ?"],
                dangerMode: true,
              }).then(function (isConfirm) {
                if(isConfirm) {
                  setValue("bannerOrder", undefined);
                  setValue("tipo", undefined);
                  setLayouts();
                  setImanges([]);
                  setLimit(8);
                  setTipo();
                  setselecImagens([0, 0, 0, 0]);
                } else {
                  refModalAddShowcase.current.closeModal();
                  reset();
                }
              })
            }).catch((error) => {
              Swal.fire({
                type: "info",
                html: showcase.formatErrorMessage(error.response),
                title: "",
              });
            });
          }
        });
      } else {
        SweetAlert({
          title: "Vitrine",
          text: `Sua vitrine está sendo criada ativada`,
          icon: "warning",
          buttons: ["Cancelar", "Confirmar"],
          dangerMode: true,
        }).then(function (isConfirm) {
          if (isConfirm) {
            showcase.createShowcase(formDataSc).then((response) => {
              loadShowcase(filter);
              SweetAlert({
                title: "Vitrine Cadastrada",
                text: response.data.message ? response.data.message : 'Vitrine incluida com sucesso!',
                icon: "success",
                buttons: ["Fechar", "Criar Novo com mesmos Filtros ?"],
                dangerMode: true,
              }).then(function (isConfirm) {
                if(isConfirm) {
                  setValue("bannerOrder", undefined);
                  setValue("tipo", undefined);
                  setLayouts();
                  setImanges([]);
                  setTipo();
                  setLimit(8);
                  setselecImagens([0, 0, 0, 0]);
                } else {
                  refModalAddShowcase.current.closeModal();
                  reset();
                }
              })
            }).catch((error) => {
              Swal.fire({
                type: "info",
                html: showcase.formatErrorMessage(error.response),
                title: "",
              });
            });
          }
        });
      }
    }
  }

  const loadShowcase = (filter) => {
    Swal.fire('Carregando Vitrines ... Aguarde');
    Swal.showLoading();
    showcase.getAllShowcases(filter).then((response) => {
      Swal.close();
      setShowcases(response.data.payload);
    }).catch((error) => {
      Swal.fire({
        type: "info",
        html: showcase.formatErrorMessage(error.response),
        title: "",
      });
    });
  }

  const searchAnuncio = ({ limit, key, deliveryType, points, price }) => {
    const anuncioService = new AnuncioService();

    if (key) {
      if (key.indexOf('&') !== -1) key = key.replace(/&/g, 'ampersand');
    }

    anuncioService.findAdsByUserSearch({
      searchString: key,
      limit: limit,
      brand: brand ? brand._id : null,
      category: listSelectedCategorization.length > 0 ? listSelectedCategorization[listSelectedCategorization.length - 1].categorization._id : null,
      condition: condition ? condition._id : null,
      deliveryType: deliveryType ? deliveryType : null,
      points: points ? points : null,
      price: price ? price : null,
      website: 'scamb',
      statusAd: 'ativo'
    }).then(response => {
      setSearchAds(response.data.payload);
    }).catch(err => {
      Swal.fire({
        type: "info",
        html: anuncioService.formatErrorMessage(err.response),
        title: "",
      });
    })
  }

  const loadMoreAds = () => {
    const newLimit = limit + 8;
    setLimit(newLimit);

    searchAnuncio({
      limit: newLimit,
      key: searchKey,
      deliveryType: deliveryType ? deliveryType : null,
      points: points ? points : null,
      price: price ? price : null,
    });
  }

  const onSearch = (data) => {
    setSearchKey(data.filterby);
    setDeliveryType(data.frete_type);
    setPoints(`${data.pontosMinimos}-${data.pontosMaximos}`);
    setPrice(data.order);

    searchAnuncio({
      limit: limit,
      key: data.filterby,
      deliveryType: data.frete_type,
      points: `${data.pontosMinimos}-${data.pontosMaximos}`,
      price: data.order
    });
  }

  const onCleanFilter = () => {
    resetSearchFilter({})
    setBrand([]);
    setCondition([]);
    setListSelectedCategorization([]);
    setLimit(8);
    setSearchKey("");
    setDeliveryType();
    setPoints();
    setPrice();

    searchAnuncio({ limit: 8, key: "" })
  }

  const addAnuncios = (e, anuncio) => {
    e.preventDefault();

    if (selectedAds) {
      const existAnuncio = selectedAds.find((a) => a._id === anuncio._id);

      if (!existAnuncio) {
        setSelectedAds([...selectedAds, anuncio]);
      }
    } else {
      setSelectedAds([anuncio]);
    }
  }

  const removeAnuncios = (e, anuncio) => {
    e.preventDefault();

    const excludesAnuncio = selectedAds.filter((a) => a._id !== anuncio._id);
    setSelectedAds(excludesAnuncio);
  }

  useEffect(() => {
    loadShowcase();
    loadInfo();
  }, []);

  useEffect(() => {
    setAllCategorizationLabel(
      listSelectedCategorization.reduce((label, selected, index) => {
        return (label += ` ${index > 0 ? ">" : ""} ${selected.categorization.nome
          }`);
      }, "")
    );
  }, [
    listSelectedCategorization.length,
    listSelectedCategorization[listSelectedCategorization.length - 1],
  ]);


  const clearFilter = (e) => {
    e.preventDefault();

    setFilter('');
    loadShowcase();
  }

  console.log("___ADS: ", selectedAds);

  return (
    <>
      <main role="main" id="view_scambers">
        <div className="_fluid-container">
          <div className="_page-section">
            <div className="icon">
              <SubjectIcon />
            </div>
            <div className="title"> Vitrine</div>
          </div>

          <div className="_wrap-filter-activity">
            <h2 className="title">Filtrar</h2>
            <div className="_filter-activity">
              <form className="_form _search" onSubmit={handleSubmitFilter(onFilter)}>
                <div className="form-group search">

                  <TextField
                    id="filterShowcase"
                    label="Filtrar *"
                    variant="outlined"
                    size={matches ? "small" : "small"}
                    name="filter"
                    fullWidth
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                    error={!!errors.qtd_points}
                    inputRef={registerFilter()}
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{
                      style: { height: '39px' }
                    }}
                  />

                  <button type="submit">
                    <SearchIcon></SearchIcon>
                  </button>
                </div>
              </form>
              <div className="excel-div">
                <button onClick={(e) => clearFilter(e)} className="excel-button">Limpar Filtros</button>
              </div>
              <div className="excel-div">
                <button className="excel-button" onClick={() => openAddShowcaseModal()}>Criar Nova Vitrine</button>
              </div>
            </div>
          </div>

          <Table bordered hover size="lg">
            <thead>
              <tr>
                <th>Título</th>
                <th>Subtítulo</th>
                <th>Tipo</th>
                <th>Data de Inicio</th>
                <th>Data Fim</th>
                <th>Ativo</th>
                <th>Editar</th>
                <th>Deletar</th>
              </tr>
            </thead>
            <tbody>
              {showcases && showcases.map(showcase =>
                <tr key={showcase._id}>
                  <td>{showcase.title}</td>
                  <td>{showcase.subtitle}</td>
                  <td>{showcase.tipo}</td>
                  <td>{format(new Date(showcase.started_date), 'dd/MM/yyyy')}</td>
                  <td>{format(new Date(showcase.expired_date), 'dd/MM/yyyy')}</td>
                  <td>{showcase.disabled ? 'Não' : 'Sim'}</td>
                  <td>
                    <Button
                      className={"btn-step btn-edit-table"}
                      color="primary"
                      autoFocus
                      style={{ height: matches ? 35 : 40 }}
                      onClick={() => openEditShowcaseModal(showcase)}
                    >
                      Editar
                    </Button>
                  </td>
                  <td>
                    <Button
                      className={"btn-step btn-edit-table"}
                      color="primary"
                      autoFocus
                      style={{ height: matches ? 35 : 40 }}
                      onClick={() => deleteShowcase(showcase)}
                    >
                      Deletar
                    </Button>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </main>


      <ModalDialog
        ref={refModalAddShowcase}
        dialogTitle="Incluir Vitrine"
        className="GridProduct"
        onCloseModal={
          <button
            type="button"
            className="btn"
            onClick={() => {
              reset({}); refModalAddShowcase.current.closeModal()
            }}
          >
            {matches ? <CloseIcon /> : <ChevronLeftIcon />}
          </button>
        }>
        <div className="_wrap-form">
          <form className="_form" onSubmit={handleSubmit(handleAddShowcase)}>
            <h1 className="_content-title">Preencha as informações para incluir uma vitrine</h1>

            <Grid container spacing={2}>
              <Grid item md={12} xs={12} style={{ marginBottom: '5px' }}>
                <FormControl variant="outlined" size="small" fullWidth>
                  <InputLabel shrink={true} id="demo-simple-select-outlined-label">
                    Tipo *
                  </InputLabel>
                  <Select
                    native
                    label="Tipo"
                    error={!!errors.tipo}
                    inputRef={register({
                      required: true
                    })}
                    inputProps={{
                      name: "tipo",
                      id: "outlined-transaction"
                    }}
                    onChange={(e) => handleShowTipo(e.target.value)}
                  >
                    <option value=""></option>
                    <option value="celular">Celular</option>
                    <option value="desktop">Desktop</option>
                  </Select>
                </FormControl>
              </Grid>
              {tipo === 'desktop' && (
                <Grid item md={12} xs={12} style={{ marginBottom: '5px' }}>
                  <FormControl variant="outlined" size="small" fullWidth>
                    <InputLabel shrink={true} id="demo-simple-select-outlined-label">
                      Layout *
                    </InputLabel>
                    <Select
                      native
                      label="Layout"
                      error={!!errors.bannerOrder}
                      inputRef={register({
                        required: true
                      })}
                      inputProps={{
                        name: "bannerOrder",
                        id: "outlined-transaction"
                      }}
                      onChange={(e) => handleShowImanges(e.target.value)}
                    >
                      <option value=""></option>
                      <option value="twoBanners">Layout dois banners</option>
                      <option value="threeBaners">Layout três banners</option>
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </Grid>

            {layouts && (
              <section className="_add-photos">
                <div className="form-group _photos">
                  <div className="_label">
                    Fotos *

                  </div>
                  <div className="_wrap-photos">
                    {layouts.map((layout, index) => (
                      <div key={layout + index} className="_wrap-photo-item">
                        <div style={{ width: "150px", height: "120px" }} className="_photo-item">
                          <input
                            type="file"
                            accept="image/x-png, image/jpeg"
                            name={`photo-upload${index + 1}`}
                            id={`photo-upload${index + 1}`}
                            className="form-control-file"
                            onChange={(e) => setSelectedImages(e, index)}
                          />
                          {selectedImages[index] ? (
                            <div className="_image-selected">
                              <img
                                src={selectedImages[index].show}
                                alt=""
                                className={"my-image "}
                              />

                              <button
                                type="button"
                                className="btn btn-transparent _delete-image"
                                onClick={(e) => removeImage(e, index)}
                              >
                                <FontAwesomeIcon
                                  icon={faTrashAlt}
                                ></FontAwesomeIcon>
                              </button>
                            </div>
                          ) : (
                            <></>
                          )}


                          <label htmlFor={`photo-upload${index + 1}`}>
                            <FontAwesomeIcon icon={faCamera}></FontAwesomeIcon>

                            <div className="_title-label">Adicionar foto principal</div>

                            <div className="_accept-formats-label">
                              JPG, GIF E PNG Somente
                            </div>
                          </label>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </section>
            )}

            <div id="view_create-ad">
              <section className="form-body">
                <Grid container spacing={2}>

                  {/* Title */}
                  <Grid item xs={12}>
                    <TextField
                      id="outlined-add-title"
                      label="Título *"
                      type="text"
                      variant="outlined"
                      size={matches ? "small" : "small"}
                      name="titulo"
                      fullWidth
                      helperText={!!errors.titulo && "Campo não pode ficar em branco"}
                      error={!!errors.titulo}
                      inputRef={register({
                        required: true,
                      })}
                      inputProps={{
                        maxLength: 40,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  {/* Subtitulo */}
                  <Grid item xs={12}>
                    <TextField
                      id="outlined-add-title"
                      label="Subtítulo *"
                      type="text"
                      variant="outlined"
                      size={matches ? "small" : "small"}
                      name="subtitulo"
                      fullWidth
                      helperText={!!errors.titulo && "Campo não pode ficar em branco"}
                      error={!!errors.titulo}
                      inputRef={register({
                        required: true,
                      })}
                      inputProps={{
                        maxLength: 40,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <TextField
                      id="outlined-login"
                      label="Data: Inicial *"
                      type="date"
                      variant="outlined"
                      size="small"
                      name="initialDate"
                      helperText={!!errors.initialDate && "Campo não pode ficar em branco"}
                      error={!!errors.initialDate}
                      fullWidth
                      inputRef={register({
                        required: true,
                      })}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <TextField
                      id="outlined-login"
                      label="Data: Final *"
                      type="date"
                      variant="outlined"
                      size="small"
                      name="endDate"
                      helperText={!!errors.endDate && "Campo não pode ficar em branco"}
                      error={!!errors.endDate}
                      fullWidth
                      inputRef={register({
                        required: true,
                      })}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item md={12} xs={12} style={{ marginBottom: '5px' }}>
                    <FormControl variant="outlined" size="small" fullWidth>
                      <InputLabel shrink={true} id="demo-simple-select-outlined-label">
                        Tipo de Filtro *
                      </InputLabel>
                      <Select
                        native
                        label="Tipo de Filtro"
                        error={!!errors.filterType}
                        inputRef={register({
                          required: true
                        })}
                        inputProps={{
                          name: "filterType",
                          id: "filterType"
                        }}
                        onChange={(e) => setTipoFiltro(e.target.value)}
                      >
                        <option value="filters">Filtros</option>
                        <option value="products">Seleção de Produtos</option>
                      </Select>
                    </FormControl>
                  </Grid>

                  {tipoFiltro === 'filters' ? (
                    <>
                      <Grid item xs={12}>
                        <h1 className="_content-title">Filtros</h1>
                        <TextField
                          id="outlined-add-title"
                          label="Filtrar por"
                          type="text"
                          variant="outlined"
                          size={matches ? "small" : "small"}
                          name="filterby"
                          fullWidth
                          inputRef={register({
                            required: false,
                          })}
                          inputProps={{
                            maxLength: 40,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>

                      {/* Condition */}
                      <Grid item xs={12}>
                        <Autocomplete
                          id="outlined-condition"
                          autoComplete={true}
                          options={conditions}
                          getOptionLabel={(option) => option.nome}
                          getOptionSelected={(option) => option._id}
                          size="small"
                          fullWidth
                          value={condition}
                          onChange={(e, value) => handleCondition(e, value)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Condição"
                              name="add_condition"
                              variant="outlined"
                              size={matches ? "small" : "small"}
                              error={!!errors.add_condition}
                              inputRef={register({
                                required: false,
                              })}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          )}
                        />
                      </Grid>

                      {/* Category */}
                      <Grid item xs={12}>
                        <TextField
                          id="outlined-category"
                          label="Categoria"
                          type="text"
                          variant="outlined"
                          size={matches ? "small" : "small"}
                          name="add_category"
                          value={allCategorizationLabel}
                          fullWidth
                          error={!!errors.add_category}
                          inputRef={register({
                            required: false,
                          })}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onClick={() => openCategorizationModal()}
                          inputProps={{
                            endadornment: (
                              <InputAdornment position="end">
                                <FontAwesomeIcon
                                  icon={faChevronRight}
                                ></FontAwesomeIcon>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>

                      {/* Brand */}
                      <Grid item xs={12}>
                        <Autocomplete
                          filterOptions={filterOptions}
                          id="outlined-brand"
                          options={brands}
                          includeInputInList
                          freeSolo
                          getOptionLabel={(option) => option.nome}
                          getOptionSelected={(option) => option._id}
                          fullWidth
                          value={brand}
                          onChange={(e, value) => handleBrand(e, value)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Marca"
                              name="brand"
                              variant="outlined"
                              size={matches ? "small" : "medium"}
                              error={!!errors.brand}
                              inputRef={register({
                                required: false,
                              })}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <FormControl variant="outlined" size="small" fullWidth>
                          <InputLabel shrink={true} id="demo-simple-select-outlined-label">
                            Ordenar por
                          </InputLabel>
                          <Select
                            native
                            label="Ordernar por"
                            inputRef={register({
                              required: false
                            })}
                            inputProps={{
                              name: "order",
                            }}
                          >
                            <option value=""></option>
                            <option value={-1}>Maior Preço</option>
                            <option value={1}>Menor Preço</option>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item md={6} xs={6}>
                        <TextField
                          id="outlined-login"
                          label="Pontos: Mínimo"
                          type="number"
                          variant="outlined"
                          size="small"
                          name="pontosMinimos"
                          defaultValue={0}
                          fullWidth
                          inputRef={register({
                            required: true,
                          })}
                        />
                      </Grid>
                      <Grid item md={6} xs={6}>
                        <TextField
                          id="outlined-login"
                          label="Pontos: Máximo"
                          type="number"
                          variant="outlined"
                          size="small"
                          name="pontosMaximos"
                          defaultValue={9999}
                          fullWidth
                          inputRef={register({
                            required: true,
                          })}
                        />
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item md={12} xs={12} className="WrapButtonSubmit">
                        <h1 className="_content-title">Seleção de Produtos</h1>
                        <Button onClick={() => openSelectProductModal()}>
                          Selecionar Produtos
                        </Button>
                      </Grid>
                    </>
                  )}

                  <Grid item md={12} xs={12}>
                    <FormControlLabel
                      control={<Switch checked={check.pause_adjustment} onChange={handleChangeCheckbox} name="pause_adjustment" color="primary" />}
                      label="Desativar Vitrine"
                    />
                  </Grid>
                </Grid>

                {tipoFiltro === 'filters' && (
                  <section className="_select-freight">
                    <div className="_label">Tipo de entrega *</div>
                    <Grid container spacing={2} className="_wrap-freight">
                      <Grid item md={2}>
                        <div className="_wrap-check-radio">
                          <label className="_check-radio">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              name="operador_logistico"
                              //error={!!errors.operador_logistico}
                              ref={register({
                                required: false
                              })}
                            />
                            <span className="overlay"></span>
                            <div className="_wrap-alt-icon">
                              <div className="_alt-icon">
                                <FontAwesomeIcon icon={faTruck}></FontAwesomeIcon>
                              </div>
                              <span>Correios</span>
                            </div>
                            <span className="check">
                              <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                            </span>
                          </label>
                        </div>
                      </Grid>
                      {false ? (<Grid item md={2}>
                        <div className="_wrap-check-radio">
                          <label className="_check-radio">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              name="aplicativo_entrega"
                              //error={!!errors.aplicativo_entrega}
                              ref={register({
                                required: false
                              })}
                            />
                            <span className="overlay"></span>
                            <div className="_wrap-alt-icon">
                              <div className="_alt-icon">
                                <FontAwesomeIcon icon={faMobile}></FontAwesomeIcon>
                              </div>
                              <span>Aplicativo de entrega</span>
                            </div>
                            <span className="check">
                              <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                            </span>
                          </label>
                        </div>
                      </Grid>) : (<></>)}
                      <Grid item md={2}>
                        <div className="_wrap-check-radio">
                          <label className="_check-radio">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              name="entrega_maos"
                              //error={!!errors.entrega_maos}
                              ref={register({
                                required: false
                              })}
                            />
                            <span className="overlay"></span>
                            <div className="_wrap-alt-icon">
                              <div className="_alt-icon">
                                <FontAwesomeIcon icon={faHandHoldingBox}></FontAwesomeIcon>
                              </div>
                              <span>Entrega em mãos</span>
                            </div>
                            <span className="check">
                              <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                            </span>
                          </label>
                        </div>
                      </Grid>
                    </Grid>
                  </section>
                )}

                <Button
                  type="submit"
                  className="btn-step"
                  style={{ height: matches ? 35 : 35, marginBottom: '20px' }}
                >
                  Concluir
                </Button>
              </section>
            </div>
          </form>
        </div>
      </ModalDialog>

      <ModalDialog
        dialogTitle="Seleção de Produtos"
        className="_modal-category GridProducts"
        maxWidth="lg"
        btnTitle="Aplicar"
        ref={refModalDialogSelectProduct}
      >
        <div className="select-product-container">
          <div className="search-products-container">
            <div className="_wrap-form" style={{ marginBottom: '20px', marginTop: '20px' }}>
              <form className="_form" onSubmit={handleSubmitSearchFilter(onSearch)}>
                <Grid container spacing={2}>
                  <Grid item md={4} xs={4}>
                    <TextField
                      id="outlined-add-title"
                      label="Filtrar por"
                      type="text"
                      variant="outlined"
                      size={matches ? "small" : "small"}
                      name="filterby"
                      fullWidth
                      inputRef={registerSearchFilter({
                        required: false,
                      })}
                      inputProps={{
                        maxLength: 40,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item md={4} xs={4}>
                    <FormControl variant="outlined" size="small" fullWidth>
                      <InputLabel shrink={true} id="demo-simple-select-outlined-label">
                        Ordenar por
                      </InputLabel>
                      <Select
                        native
                        label="Ordernar por"
                        inputRef={registerSearchFilter({
                          required: false
                        })}
                        inputProps={{
                          name: "order",
                        }}
                      >
                        <option value=""></option>
                        <option value={-1}>Maior Preço</option>
                        <option value={1}>Menor Preço</option>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item md={4} xs={4}>
                    <TextField
                      id="outlined-category"
                      label="Categoria"
                      type="text"
                      variant="outlined"
                      size={matches ? "small" : "small"}
                      name="add_category"
                      value={allCategorizationLabel}
                      fullWidth
                      error={!!errors.add_category}
                      inputRef={registerSearchFilter({
                        required: false,
                      })}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onClick={() => openCategorizationModal()}
                      inputProps={{
                        endadornment: (
                          <InputAdornment position="end">
                            <FontAwesomeIcon
                              icon={faChevronRight}
                            ></FontAwesomeIcon>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item md={4} xs={4}>
                    <Autocomplete
                      filterOptions={filterOptions}
                      id="outlined-brand"
                      options={brands}
                      includeInputInList
                      freeSolo
                      getOptionLabel={(option) => option.nome}
                      getOptionSelected={(option) => option._id}
                      fullWidth
                      value={brand}
                      onChange={(e, value) => handleBrand(e, value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Marca"
                          name="brand"
                          variant="outlined"
                          size={matches ? "small" : "medium"}
                          error={!!errors.brand}
                          inputRef={registerSearchFilter({
                            required: false,
                          })}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item md={4} xs={4}>
                    <Autocomplete
                      id="outlined-condition"
                      autoComplete={true}
                      options={conditions}
                      getOptionLabel={(option) => option.nome}
                      getOptionSelected={(option) => option._id}
                      size="small"
                      fullWidth
                      value={condition}
                      onChange={(e, value) => handleCondition(e, value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Condição"
                          name="add_condition"
                          variant="outlined"
                          size={matches ? "small" : "small"}
                          error={!!errors.add_condition}
                          inputRef={registerSearchFilter({
                            required: false,
                          })}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item md={4} xs={4}>
                    <FormControl variant="outlined" size="small" fullWidth>
                      <InputLabel shrink={true} id="demo-simple-select-outlined-label">
                        Tipo de Frete
                      </InputLabel>
                      <Select
                        native
                        label="Tipo de Frete"
                        inputRef={registerSearchFilter({
                          required: false
                        })}
                        inputProps={{
                          name: "frete_type",
                        }}
                      >
                        <option value=""></option>
                        <option value={"operador_logistico"}>Correios</option>
                        <option value={"entrega_maos"}>Entrega em mãos</option>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item md={4} xs={4}>
                    <TextField
                      id="outlined-login"
                      label="Pontos: Mínimo"
                      type="number"
                      variant="outlined"
                      size="small"
                      name="pontosMinimos"
                      defaultValue={0}
                      fullWidth
                      inputRef={registerSearchFilter({
                        required: true,
                      })}
                    />
                  </Grid>

                  <Grid item md={4} xs={4}>
                    <TextField
                      id="outlined-login"
                      label="Pontos: Máximo"
                      type="number"
                      variant="outlined"
                      size="small"
                      name="pontosMaximos"
                      defaultValue={9999}
                      fullWidth
                      inputRef={registerSearchFilter({
                        required: true,
                      })}
                    />
                  </Grid>

                  <Grid item md={2} xs={2}>
                    <Button type='submit' style={{ marginTop: '3px' }}>
                      Aplicar
                    </Button>
                  </Grid>

                  <Grid item md={2} xs={2}>
                    <Button color="secondary" style={{ marginTop: '3px' }} onClick={() => onCleanFilter()}>
                      Limpar
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </div>

            <Grid container spacing={2}>
              {searchAds && searchAds.map((ad, index) => (
                <Grid item lg={3} md={4} sm={4} xs={6} key={index}>
                  <GridProduct
                    link={ad.url_encurtada}
                    image={
                      showcase.loadImage(ad.fotos[0])
                        ? showcase.loadImage(ad.fotos[0])
                        : ""
                    }
                    weight={ad.tamanho_exibicao?.nome}
                    title={ad.titulo}
                    discount={
                      ad.pontos_de
                        ? ad.pontos_de.$numberDecimal
                        : ""
                    }
                    price={
                      ad.pontos_por
                        ? ad.pontos_por.$numberDecimal
                        : ""
                    }
                    type={ad.tipo_anuncio}
                    cashback={ad.cashback}
                    cashDiscount={ad.valor_de}
                    cashPrice={ad.valor_por}
                    website='scamb'
                    onClick={(e) => addAnuncios(e, ad)}
                  />
                </Grid>
              ))}

            </Grid>

            <div className="_wrap-load-more">
              {limit <= searchAds?.length ? (<button type="submit" onClick={() => loadMoreAds()} className="btn btn-red">Carregar mais</button>) : null}
            </div>
          </div>

          {selectedAds?.length > 0 && (
            <div className="alredy-select-container">
              <div className="_title-select-product">Produtos já selecionados</div>

              <List className="alredy-select-list">
                {selectedAds && selectedAds.map((ad, index) => (
                  <ProductCard
                    key={ad._id}
                    image={showcase.loadImage(ad.fotos[0])}
                    title={ad.titulo}
                    // subtitle={`R$${ad?.taxa_scamb?.$numberDecimal || 0}`}
                    url={ad.url_encurtada}
                  >
                    <ButtonMaterial
                      endIcon={<DeleteIcon color="secondary" />}
                      onClick={(e) => { removeAnuncios(e, ad) }}
                    />
                  </ProductCard>
                ))}
              </List>
            </div>
          )}
        </div>
      </ModalDialog>

      <ModalDialog
        customMethod={onSelectCategorization}
        dialogTitle="Categorias"
        btnTitle="Aplicar"
        className="_modal-category"
        maxWidth="xs"
        ref={refModalDialogCategory}
      >
        <span className="_content">
          <a href="#!" className="tag-selected-radio">
            {listSelectedCategorization.map(
              (selectedCategorization, index) => {
                if (index === 0) {
                  return (
                    <div
                      onClick={() =>
                        handleCategorizationBack(
                          selectedCategorization.oldCategorizationList,
                          index
                        )
                      }
                      key={index}
                      className="_title-selected _only"
                    >
                      <FontAwesomeIcon
                        className="icon"
                        icon={faChevronLeft}
                      ></FontAwesomeIcon>
                      <span class="_text">

                        {selectedCategorization.categorization.nome}
                      </span>
                    </div>
                  );
                } else {
                  return (
                    <div
                      onClick={() =>
                        handleCategorizationBack(
                          selectedCategorization.oldCategorizationList,
                          index
                        )
                      }
                      key={index}
                      className="_title-selected _only _multiple"
                    >
                      <span class="_text">

                        {selectedCategorization.categorization.nome}
                      </span>
                    </div>
                  );
                }
              }
            )}
          </a>

          <ul id="drilldown">
            {listActiveCategorization.map((categorization, index) => (
              <li key={index}>
                <label>
                  <input
                    type="radio"
                    name="select_category_lvl_1"
                    checked={
                      category === categorization.nome ? "checked" : ""
                    }
                    onChange={() => handleCategorization(categorization)}
                  />
                  <span className="_name">{categorization.nome}</span>
                  {hasChindren(categorization) ? (
                    <div className="icon">
                      <FontAwesomeIcon
                        icon={faChevronRight}
                      ></FontAwesomeIcon>
                    </div>
                  ) : (
                    <></>
                  )}
                </label>
              </li>
            ))}
          </ul>
        </span>
      </ModalDialog>
    </>
  )
}

export default Showcase;