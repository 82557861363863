import Service from './Service';

export default class AdTransfer extends Service {

  createAdTransfer({ scamberOrigem, scamberDestino, anunciosIds, commission, initialDate, endDate }) {
    return this.axiosInstance.post(`/adtransfer`, { scamberOrigem, scamberDestino, anunciosIds, commission, initialDate, endDate });
  }

  returnAdTransfer({ adTransfer, optionReturn }) {
    return this.axiosInstance.put(`/adtransfer`, { adTransfer, optionReturn });
  }

  editAdTransfer({ adTransfer, allAds, commission, initialDate, endDate }) {
    return this.axiosInstance.put(`/adtransfer/edit`, { adTransfer, allAds, commission, initialDate, endDate });
  }

  listAllAdTransfer({ search }) {
    return this.axiosInstance.get(`/adtransfer/listall?search=${search ? search : ''}`);
  }

  getOneTranfer({ adTransferId }) {
    return this.axiosInstance.get(`/adtransfer/listone/${adTransferId}`);
  }
  
}