import Service from './Service';

export default class Dowload extends Service {

    constructor() {
        super();
    }

    getAccountFile(filtros) {
        return this.axiosInstance.post(`/download/accounts`, filtros, { responseType:'blob' });
    }

    getOrderFile(startDate, endDate, nameFilter, filter, website) {
        return this.axiosInstance.post(`/download/orders${startDate?'?startDate=' + startDate:''}${endDate?'&endDate=' + endDate:''}${filter?'&' + nameFilter + '=' + filter:''}${website? '&website=' + website : ''}`, {}, { responseType:'blob' });
    }

    getScamberFile(filter) {
        return this.axiosInstance.post(`/download/scambers`, { filter: filter }, { responseType:'blob' });
    }

    getReversalOrderFile(startDate, endDate, nameFilter, filter) {
        return this.axiosInstance.post(`/download/reservalorders${startDate?'?startDate=' + startDate:''}${endDate?'&endDate=' + endDate:''}${filter?'&' + nameFilter + '=' + filter:''}`, {}, { responseType:'blob' });
    }

    getOrderPointsrFile(startDate, endDate, filter) {
        return this.axiosInstance.post(`/download/orderpoints${startDate?'?startDate=' + startDate:''}${endDate?'&endDate=' + endDate:''}${filter?'&scamber=' + filter : ''}`, {}, { responseType:'blob' });
    }

    getAnuncioFile(startDate, endDate, website) {
        return this.axiosInstance.post(`/download/anuncios${website ? '?website='+website : '?website=scamb'}${startDate?'&startDate=' + startDate:''}${endDate?'&endDate=' + endDate:''}`, {}, { responseType:'blob' });
    }

    getAnuncioByBrandsFile(search, website) {
        return this.axiosInstance.post(`/download/anuncios/marcas${website ? '?website='+website : '?website=scamb'}${search?'&search=' + search : ''}`, {}, { responseType:'blob' });
    }

    getAnuncioBySubCategoriesFile(category, startDate, endDate, website) {
        return this.axiosInstance.post(`/download/anuncios/subcategories?categoryid=${category}${website ? '&website='+website : '&website=scamb'}${startDate?'&startDate=' + startDate:''}${endDate?'&endDate=' + endDate:''}`, {}, { responseType:'blob' });
    }
}