//===============================================================================================================
// #1 - Import * Base
//===============================================================================================================
import React, { useRef, useEffect, useState } from "react";
import { useForm } from 'react-hook-form';
import { format } from 'date-fns';

//===============================================================================================================
// #5 - Import * Sweet Alert
//===============================================================================================================
import Swal from 'sweetalert2';
import SweetAlert from 'sweetalert';

//===============================================================================================================
// #5 - Import * Utils
//===============================================================================================================
import formatTitle from "../../utils/FormatTitle";

//===============================================================================================================
// #1 - Import * Bootstrap
//===============================================================================================================
import { Table, Button, Pagination } from "react-bootstrap";

//===============================================================================================================
// #1 - Import * Material UI Components
//===============================================================================================================
import { Grid, TextField, Select, useMediaQuery, makeStyles } from "@material-ui/core";
import Switch from '@material-ui/core/Switch';
import SubjectIcon from "@material-ui/icons/Subject";
import SearchIcon from "@material-ui/icons/Search";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import CloseIcon from "@material-ui/icons/Close";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Scamber from './../../services/Scamber';
import Download from './../../services/Dowload';
import Coupons from './../../services/Coupons';

import ModalDialog from "../../components/ModalDialog";
import ExtractModal from "../../components/ExtractModal";

//===============================================================================================================
// #6 - Import * UI Components
//===============================================================================================================
import { PricePoints } from "../../components/ui/price";
import { Accordion } from "../../components/ui/accordion";
import { ListContent, ListItem } from "../../components/ui/list-content";

//===============================================================================================================
// #7 - Import * FontAwesome Icons
//===============================================================================================================
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle, faAlignLeft, faCoins, faBookmark, faCalendarAlt, faEllipsisV, faFileInvoiceDollar, faEdit } from "@fortawesome/pro-light-svg-icons";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";

//===============================================================================================================
// #1 - Material UI Components - Styles
//===============================================================================================================
const useStyles = makeStyles({
  center: {
    margin: "auto",
  },
  resize: {
    fontSize: "10px",
  },
  inputSize: {
    height: 50,
  },
});

//===============================================================================================================
// #1 - History Block
//===============================================================================================================
const HistoryBlock = (props) => {

  const checkStatus = (status) => {
    switch (status) {
      case 'PAGAMENTO_PENDENTE':
        return 'Pagamento Pendente';
      case 'PAGO':
        return 'Pago';
      case 'PONTUADO':
        return 'Pontuado';
      default:
        return 'Status Não Mapeado'
    }
  }

  return (

    <div className="_history-block">
      <div className="_cols">
        <div className="_col">
          <h1 className="title"> {formatTitle(props.title)} </h1>
          <div className="items">
            <div className="item">
              <div className="icon">
                <Icon icon={faBookmark} />
              </div>
              <h4 className="title">
                {formatTitle(props.type)}
              </h4>
            </div>
            <div className="item">
              <div className="icon">
                <Icon icon={faCalendarAlt} />
              </div>
              <h4 className="title">
                {props.date}
              </h4>
            </div>
            <div className="item">
              <div className="icon">
                <Icon icon={faCoins} />
              </div>
              <h4 className="title">
                <PricePoints value={props.points} className={props.color} />
              </h4>
            </div>
            <div className="item">
              <div className="icon">
                <Icon icon={faFileInvoiceDollar} />
              </div>
              <h4 className="title">
                {checkStatus(props.status)}
              </h4>
            </div>
          </div>
          <div className="item _description">
            <div className="icon">
              <Icon icon={faAlignLeft} />
              <span>Descrição</span>
            </div>
            <h4 className="title">
              {props.description}
            </h4>
          </div>
        </div>
        <div className="_col">
          <button type="button" className="btn" onClick={props.onClick}>
            <Icon icon={faEllipsisV} />
          </button>
        </div>
      </div>
    </div>
  )
}

//===============================================================================================================
// #1 - Render
//===============================================================================================================
function Scambers() {
  const coupon = new Coupons();

  // Styles
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:600px)");

  // * Refs
  const refModalDialog = useRef();
  const refModalDialogTax = useRef();
  const refModalDialogEditTax = useRef();
  const refModalDialogExtract = useRef();
  const refSellPointsDialogModal = useRef();


  const { handleSubmit, register, errors, reset } = useForm()
  const { handleSubmit: handleSubmitPoints, register: registerPoints, errors: errorsPoints, reset: resetPoints } = useForm()
  const { handleSubmit: handleSubmitTax, register: registerTax, errors: errorsTax, reset: resetTax } = useForm()
  const { handleSubmit: handleSubmitEditTax, register: registerEditTax, errors: errorsEditTax, reset: resetEditTax } = useForm()

  // * States
  const [type, setType] = useState("");
  const [listScambers, setListScambers] = useState({ docs: [] });
  const [activePage, setActivePage] = useState(1);
  const [activeFilter, setActiveFilter] = useState();
  const [selectedScamber, setSelectedScamber] = useState();
  const [maxRate, setMaxRate] = useState(0);
  const [check, setCheck] = React.useState({ pause_adjustment: false });
  const [descont, setDescont] = useState();

  const [pointsAwaitingApproval, setPointsAwaitingApproval] = useState(0);
  const [awpHistoric, setAwpHistoric] = useState([]);
  const [justRead, setJustRead] = useState(false);


  const handleChangeCheckbox = (event) => {
    const { target } = event;
    setCheck({ ...check, [target.name]: target.checked });
  };

  useEffect(() => {
    const scamber = new Scamber();
    scamber.loadScamberPermissions().then((response) => {
      if (response.data.type === 'success') {
        const permissionRead = response.data.payload.permissions.scamb.find(permision => permision === 'scambersRead');
        if(permissionRead) setJustRead(true);
      }
    }).catch((error) => {})

    loadScambers(1, activeFilter);
  }, []);


  const nextPage = () => {
    const nextPageNumber = activePage + 1;

    setActivePage(nextPageNumber);
    loadScambers(nextPageNumber, activeFilter);
  }

  const previousPage = () => {
    const previousPageNumber = activePage - 1;

    setActivePage(previousPageNumber);
    loadScambers(previousPageNumber, activeFilter);
  }

  const firstPage = () => {
    setActivePage(1);
    loadScambers(1, activeFilter);
  }

  const lastPage = () => {
    setActivePage(listScambers.totalPages);
    loadScambers(listScambers.totalPages, activeFilter);
  }

  const getScambersFile = () => {
    const download = new Download();

    Swal.fire('Gerando arquivo ... Aguarde');
    Swal.showLoading();
    download.getScamberFile(activeFilter).then(response => {
      var fileURL = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv;charset=utf-8;' }));
      var fileLink = document.createElement('a');
      const newDate  = new Date();
      
      fileLink.href = fileURL;
      fileLink.setAttribute('download', `scambers_${newDate.getDate()}_${newDate.getMonth() + 1}_${newDate.getFullYear()}.csv`);
      document.body.appendChild(fileLink);

      Swal.close();
      fileLink.click();
    }).catch(error => {
      Swal.close();
      Swal.fire({
        type: "info",
        html: download.formatErrorMessage(error.response),
        title: "",
      });
    })
  }

  const loadScambers = (page, filter) => {

    const scamber = new Scamber();

    Swal.fire('Carregando lista ... Aguarde');
    Swal.showLoading();
    scamber.listarScambers(page, 10, filter).then((response) => {
      Swal.close();
      setListScambers(response.data.payload);
    })
      .catch((error) => {
        Swal.fire({
          type: "info",
          html: scamber.formatErrorMessage(error.response),
          title: "",
        });
      });

  }

  const onFilter = (data) => {
    setActivePage(1);
    setActiveFilter(data.filter);

    loadScambers(1, data.filter);
  }

  const openPonctuateScamberModal = (scamber) => {
    setSelectedScamber(scamber);
    refModalDialog.current.openModal();
  }

  const openScamberTaxModal = (scamber) => {
    setSelectedScamber(scamber);
    refModalDialogTax.current.openModal();
  }

  const openScamberEditTaxModal = (scamber) => {
    setSelectedScamber(scamber);
    setDescont(scamber.coupon);
    setCheck({ pause_adjustment: scamber.coupon.disabled });

    refModalDialogEditTax.current.openModal();
  }

  const getOperationLabel = (operation) => {
    switch (operation) {
      case 'ACOMPRA':
        return 'Ajustes de Compra';
      case 'AINDICACAO':
        return 'Ajustes de Indicação';
      case 'ACOMPRAPTO':
        return 'Ajuste de Compra de Pontos';
      case 'AADESAO':
        return 'Ajuste de Adesão'
      default:
        return '';
    }
  }

  const ponctuateScamber = (data, e) => {
    //
    const scamber = new Scamber();
    const pointInfo = {
      scamberID: selectedScamber._id,
      account: _checkAccount(data.operation),
      operation: data.operation,
      typeOperation: data.operationType,
      value: data.points,
      description: data.description
    }

    const template = (`<div class="swall-informations">
      <p><strong>Nome:</strong> <span>${selectedScamber.nome}</span></p>
      <p><strong>Email:</strong> <span>${selectedScamber.email}</span></p>
      <p><strong>Operação:</strong> <span>${getOperationLabel(pointInfo.operation)}</span></p>
      <p><strong>Tipo de Operação:</strong> <span>${pointInfo.typeOperation === 'C' ? 'Crédito' : 'Débito'}</span></p>
      <p><strong>Valor:</strong> <span>${pointInfo.value}</span></p>
      <p><strong>Descrição:</strong> <span>${pointInfo.description}</span></p>  
    </div>
    `);

    SweetAlert({
      html: true,
      title: "Confirmar a operação?",
      icon: "info",
      buttons: ["Não, cancelar", "Sim, pontuar"],
      dangerMode: true,
      content: {
        element: 'div',
        attributes: {
          innerHTML: `${template}`,
        },
      }

    }).then(function (isConfirm) {
      if (isConfirm) {

        scamber.pontuarScamber(pointInfo).then((response) => {
          refModalDialog.current.closeModal();
          resetPoints();

          SweetAlert({
            title: "",
            text: response.data.message ? response.data.message : 'Pontuação realizada com sucesso!',
            icon: "success",
          }).then(() => {
            loadScambers(activePage, activeFilter)
          });


        })
          .catch((error) => {
            refModalDialog.current.closeModal();
            resetPoints();

            SweetAlert({
              icon: "error",
              text: scamber.formatErrorMessage(error.response),
              title: "",
            });

            console.log("ERROR -> ", error);
          });

      } else {
        SweetAlert.close();
      }
    });

  }

  const _checkAccount = (operation) => {
    switch (operation) {
      case 'ACOMPRA':
        return 'ADQ001';
      case 'AINDICACAO':
        return 'BON001';
      case 'ACOMPRAPTO':
        return 'CPR001';
      case 'AADESAO':
        return 'BON001';
      case 'AANUNCIO':
        return 'BON001';
      default:
        return '';
    }
  }

  const loadLoyaltyExtract = (scamberData) => {
    setSelectedScamber(scamberData)
    refModalDialogExtract.current.openModal();
  }

  const handleSubmitDescont = (data) => {
    const descontObject = {
      scamber: selectedScamber._id,
      taxaPercentage: data.taxa_percentage,
      fretePercentage: data.frete_percentage,
      pontosPercentage: data.pontos_percentage,
      startedDate: data.tax_min_date,
      expiredDate: data.tax_max_date,
      description: data.description
    }

    coupon.createCoupon(descontObject).then(response => { }).catch(error => {
      SweetAlert({
        icon: "error",
        text: coupon.formatErrorMessage(error.response),
        title: "",
      });
    }).finally(() => {
      SweetAlert({
        title: "",
        text: 'Desconto incluido com sucesso!',
        icon: "success",
      }).then(() => {
        SweetAlert.close();
        refModalDialogTax.current.closeModal();
        loadScambers(activePage, activeFilter)
        resetTax();
      })
    })
  }

  const handleEditDescont = (data) => {
    console.log(data);
    const updateDescont = {
      cuponId: descont._id,
      newTaxaPercentage: data.taxa_percentage,
      newFretePercentage: data.frete_percentage,
      newPontosPercentage: data.pontos_percentage,
      newstartedDate: data.tax_min_date,
      newexpiredDate: data.tax_max_date,
      description: data.description,
      disabled: check.pause_adjustment
    }

    coupon.updateCoupon(updateDescont).then(response => { }).catch(error => {
      SweetAlert({
        icon: "error",
        text: coupon.formatErrorMessage(error.response),
        title: "",
      });
    }).finally(() => {
      SweetAlert({
        title: "",
        text: 'Desconto modificado com sucesso!',
        icon: "success",
      }).then(() => {
        SweetAlert.close();
        refModalDialogEditTax.current.closeModal();
        loadScambers(activePage, activeFilter)
        resetTax();
      })
    })
  }

  const clearFilter = (e) => {
    e.preventDefault();

    setActivePage(1);
    setActiveFilter('');

    loadScambers(1, '');
  }

  return (
    <>
      <main role="main" id="view_scambers">
        <div className="_fluid-container">
          <div className="_page-section">
            <div className="icon">
              <SubjectIcon />
            </div>
            <div className="title">Scambers</div>
          </div>

          <div className="_wrap-filter-activity">
            <h2 className="title">Filtrar</h2>
            <div className="_filter-activity">
              <form className="_form _search" onSubmit={handleSubmit(onFilter)}>
                <div className="form-group search">

                  <TextField
                    id="outlined-add-description"
                    label="Filtrar *"
                    variant="outlined"
                    size={matches ? "small" : "small"}
                    name="filter"
                    fullWidth
                    value={activeFilter}
                    onChange={(e) => setActiveFilter(e.target.value)}
                    error={!!errors.qtd_points}
                    inputRef={register()}
                    InputLabelProps={{
                      shrink: true

                      //className: 'form-control'
                    }}
                    inputProps={{
                      style: { height: '39px' }
                    }}
                  />

                  <button type="submit">
                    <SearchIcon></SearchIcon>
                  </button>
                </div>
              </form>
              <div className="excel-div">
                <button onClick={(e) => clearFilter(e)} className="excel-button">Limpar Filtros</button>
              </div>
              <div className="excel-div">
                <button onClick={getScambersFile} className="excel-button">Exportar .csv</button>
              </div>
            </div>
          </div>

          <Table bordered hover size="lg">
            <thead>
              <tr>
                <th>Nome</th>
                <th>E-Mail</th>
                <th>Loja</th>
                <th>Isenção Aplicada</th>
                <th>Pontos</th>
                <th>Ações</th>
                <th>Isenção taxa</th>
              </tr>
            </thead>
            <tbody>
              {listScambers.docs.map(scamber =>
                <tr key={scamber._id}>
                  <td>{scamber.nome}</td>
                  <td>{scamber.email}</td>
                  <td>{scamber?.minha_loja?.titulo}</td>
                  <td>
                    <Button disabled={justRead ? true : scamber.coupon ? false : true} className="_btn-view-points" onClick={() => openScamberEditTaxModal(scamber)}>
                      <span>{!scamber.coupon ? 'Não' : scamber.coupon.disabled ? 'Desativado' : 'Sim'}</span>
                      <div className="plus">
                        <Icon icon={faEdit} />
                      </div>
                    </Button>
                  </td>
                  <td>
                    <Button className="_btn-view-points" onClick={() => loadLoyaltyExtract(scamber)}>
                      <span>{scamber.saldo}</span>
                      <div className="plus">
                        <Icon icon={faPlus} />
                      </div>
                    </Button>
                  </td>
                  <td>
                    <Button
                      className={"btn-step btn-edit-table"}
                      disabled={justRead}
                      color="primary"
                      autoFocus
                      style={{ height: matches ? 35 : 40 }}
                      onClick={() => openPonctuateScamberModal(scamber)}
                    >
                      Pontuar
                      {/*<AddIcon fontSize="inherit" />*/}
                    </Button>
                  </td>
                  <td>
                    <Button
                      disabled={justRead ? true : scamber.coupon ? true : false}
                      className={"btn-step btn-edit-table"}
                      color="primary"
                      autoFocus
                      style={{ height: matches ? 35 : 40 }}
                      onClick={() => openScamberTaxModal(scamber)}
                    >
                      Ajustar
                      {/*<AddIcon fontSize="inherit" />*/}
                    </Button>
                  </td>
                </tr>
              )}

            </tbody>
          </Table>

          {listScambers.docs.length ?
            <Pagination style={{ float: 'right' }}>
              <Pagination.First onClick={() => firstPage()} disabled={!listScambers.hasPrevPage} />
              <Pagination.Prev onClick={() => previousPage()} disabled={!listScambers.hasPrevPage} />
              <Pagination.Item>{activePage}</Pagination.Item>
              <Pagination.Next onClick={() => nextPage()} disabled={!listScambers.hasNextPage} />
              <Pagination.Last onClick={() => lastPage()} disabled={!listScambers.hasNextPage} />
            </Pagination>
            :
            <></>
          }


        </div>
      </main>

      <ModalDialog 
        ref={refModalDialogExtract} 
        dialogTitle="Extrato de pontos" 
        className="_dialog-details" 
        onCloseModal={<button type="button" className="btn" onClick={() => { refModalDialogExtract.current.closeModal() }}> {matches ? <CloseIcon /> : <ChevronLeftIcon />} </button>}
        maxWidth="md"
        reset={true}
      >
      
        <ExtractModal
          contaLoyalty={selectedScamber?.conta_loyalty}
          scamber={selectedScamber?._id}
          scamberSaldo={selectedScamber?.saldo}
        />

      </ModalDialog>

      <ModalDialog ref={refSellPointsDialogModal} dialogTitle="Extrato aguardando liberação" className="_dialog-details">

        <Accordion
          opened={true}
          awaysOpened={true}
          child={
            <ListContent bgColor="#FFFFFF">
              <ListItem title="Total">
                <PricePoints value={pointsAwaitingApproval? pointsAwaitingApproval:0} />
              </ListItem>
            </ListContent>
          }
        >

          {awpHistoric.map((item, index) => 
              (<HistoryBlock key={index} type={item.type} title={item.title} date={item.date.split(" ")[0]} 
              points={item.points} color="green" 
              status={item.status}/>)
          )}
          
        </Accordion>

    </ModalDialog>

      <ModalDialog ref={refModalDialog} dialogTitle="Ações" onCloseModal={<button type="button" className="btn" onClick={() => { resetPoints({}); refModalDialog.current.closeModal() }}> {matches ? <CloseIcon /> : <ChevronLeftIcon />} </button>}>
        <div className="_wrap-form">
          <form className="_form" onSubmit={handleSubmitPoints(ponctuateScamber)}>
            <h1 className="_content-title">Selecione uma ação</h1>
            <p><strong>Nome:</strong> {selectedScamber?.nome} </p>
            <p><strong>Email:</strong> {selectedScamber?.email} </p>
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <TextField
                  id="outlined-login"
                  label="Pontos *"
                  type="number"
                  variant="outlined"
                  size="small"
                  inputRef={registerPoints({
                    required: true
                  })}
                  name="points"
                  fullWidth
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <FormControl variant="outlined" size="small" fullWidth>
                  <InputLabel shrink={true} id="demo-simple-select-outlined-label">
                    Tipo
                  </InputLabel>
                  <Select
                    native
                    label="Age"
                    inputRef={registerPoints({
                      required: true
                    })}
                    inputProps={{
                      name: "operation",
                      id: "outlined-point-type",

                    }}
                  >
                    <option value="">Selecione</option>
                    <option value="ACOMPRA">Ajustes de Compra</option>
                    <option value="AINDICACAO">Ajustes de Indicação</option>
                    <option value="ACOMPRAPTO">Ajuste de Compra de Pontos</option>
                    <option value="AADESAO">Ajuste de Adesão</option>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item md={6} xs={6}>
                <FormControl variant="outlined" size="small" fullWidth>
                  <InputLabel shrink={true} id="demo-simple-select-outlined-label">
                    Transação
                  </InputLabel>
                  <Select
                    native
                    label="Age"
                    inputRef={registerPoints({
                      required: true
                    })}
                    inputProps={{
                      name: "operationType",
                      id: "outlined-transaction"
                    }}
                  >
                    <option value="">Selecione</option>
                    <option value="D">Débito</option>
                    <option value="C">Crédito</option>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item md={12} xs={12}>
                <InputLabel shrink={true} id="demo-simple-select-outlined-label">
                  Descrição *
                  </InputLabel>
                <FormControl variant="outlined" size="small" fullWidth>
                  <textarea
                    maxLength="100"
                    style={{ resize: 'none', borderColor: '#eaeaea', borderRadius: '6px' }}
                    native
                    rows="3"
                    name="description"
                    ref={registerPoints({
                      required: true
                    })}
                    inputProps={{
                      name: "description",
                      id: "outlined-transaction"
                    }}
                  >
                  </textarea>
                </FormControl>
              </Grid>

            </Grid>

            <Button
              className={"btn-step " + classes.center}
              color="primary"
              autoFocus
              type="submit"
              style={{ height: matches ? 35 : 40 }}
            >
              Concluir
            </Button>
          </form>
        </div>
      </ModalDialog>

      {/* Aplicar Taxas */}
      <ModalDialog ref={refModalDialogTax} dialogTitle="Aplicar Insenção de Taxas" onCloseModal={<button type="button" className="btn" onClick={() => { resetTax({}); refModalDialogTax.current.closeModal() }}> {matches ? <CloseIcon /> : <ChevronLeftIcon />} </button>}>
        <div className="_wrap-form">
          <form className="_form" onSubmit={handleSubmitTax(handleSubmitDescont)}>
            <h1 className="_content-title">Selecione uma ação</h1>
            <p><strong>Nome:</strong> {selectedScamber?.nome} </p>
            <p><strong>Email:</strong> {selectedScamber?.email} </p>
            <Grid container spacing={2}>

              {/* <Grid item md={12} xs={12}>
                <FormControl variant="outlined" size="small" fullWidth>
                  <InputLabel shrink={true} id="demo-simple-select-outlined-label">
                    Tipo
                  </InputLabel>
                  <Select
                    native
                    label="rate_type"
                    inputRef={registerTax({
                      required: true
                    })}
                    inputProps={{
                      name: "rate_type",
                      id: "outlined-point-type",

                    }}
                  >
                    <option value="">Selecione</option>
                    <option value="freight">Desconto de Frete</option>
                    <option value="tax">Desconto de Taxa Scamb</option>
                  </Select>
                </FormControl>
              </Grid> */}

              <Grid item md={6} xs={12}>
                <TextField
                  id="outlined-login"
                  label="Data: Inicial"
                  type="date"
                  variant="outlined"
                  size="small"
                  inputRef={registerTax({
                    required: true,
                  })}
                  name="tax_min_date"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  id="outlined-login"
                  label="Data: Final"
                  type="date"
                  variant="outlined"
                  size="small"
                  inputRef={registerTax({
                    required: true,
                  })}
                  name="tax_max_date"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item md={12} xs={12}>
                <TextField
                  id="outlined-login"
                  label="Porcentagem de desconto nos Pontos *"
                  type="number"
                  variant="outlined"
                  size="small"
                  InputProps={{ inputProps: { min: 0, max: 100 } }}
                  inputRef={registerTax({
                    required: true,
                  })}
                  inputProps={{
                    maxLength: 3,
                  }}
                  onChange={({ target }) => setMaxRate(target.value)}
                  name="pontos_percentage"
                  defaultValue={0}
                  fullWidth
                />
              </Grid>

              <Grid item md={12} xs={12}>
                <TextField
                  id="outlined-login"
                  label="Porcentagem de desconto na Taxa *"
                  type="number"
                  variant="outlined"
                  size="small"
                  InputProps={{ inputProps: { min: 0, max: 100 } }}
                  inputRef={registerTax({
                    required: true,
                  })}
                  inputProps={{
                    maxLength: 3,
                  }}
                  onChange={({ target }) => setMaxRate(target.value)}
                  name="taxa_percentage"
                  defaultValue={0}
                  fullWidth
                />
              </Grid>

              <Grid item md={12} xs={12}>
                <TextField
                  id="outlined-login"
                  label="Porcentagem de desconto no Frete *"
                  type="number"
                  variant="outlined"
                  size="small"
                  InputProps={{ inputProps: { min: 0, max: 100 } }}
                  inputRef={registerTax({
                    required: true,
                  })}
                  inputProps={{
                    maxLength: 3,
                  }}
                  onChange={({ target }) => setMaxRate(target.value)}
                  name="frete_percentage"
                  defaultValue={0}
                  fullWidth
                />
              </Grid>

              

              <Grid item md={12} xs={12}>
                <InputLabel shrink={true} id="demo-simple-select-outlined-label">
                  Descrição *
                  </InputLabel>
                <FormControl variant="outlined" size="small" fullWidth>
                  <textarea
                    maxLength="100"
                    style={{ resize: 'none', borderColor: '#eaeaea', borderRadius: '6px' }}
                    native
                    rows="3"
                    name="description"
                    ref={registerTax({
                      required: true
                    })}
                    inputProps={{
                      name: "tax_description",
                      id: "outlined-transaction"
                    }}
                  >
                  </textarea>
                </FormControl>
              </Grid>

            </Grid>

            <Button
              className={"btn-step " + classes.center}
              color="primary"
              autoFocus
              type="submit"
              style={{ height: matches ? 35 : 40 }}
            >
              Concluir
            </Button>
          </form>
        </div>
      </ModalDialog>

      {/* Editar Taxas */}
      <ModalDialog ref={refModalDialogEditTax} dialogTitle="Editar Insenção de Taxas" onCloseModal={<button type="button" className="btn" onClick={() => { resetTax({}); refModalDialogEditTax.current.closeModal() }}> {matches ? <CloseIcon /> : <ChevronLeftIcon />} </button>}>
        <div className="_wrap-form">
          {descont && (
            <form className="_form" onSubmit={handleSubmitEditTax(handleEditDescont)}>
              <h1 className="_content-title">Selecione uma ação</h1>
              <p><strong>Nome:</strong> {selectedScamber?.nome} </p>
              <p><strong>Email:</strong> {selectedScamber?.email} </p>
              <Grid container spacing={2}>

                {/* <Grid item md={12} xs={12}>
                  <FormControl variant="outlined" size="small" fullWidth>
                    <InputLabel shrink={true} id="demo-simple-select-outlined-label">
                      Tipo
                  </InputLabel>
                    <Select
                      native
                      label="rate_type"
                      inputRef={registerEditTax({
                        required: true
                      })}
                      inputProps={{
                        name: "rate_type",
                        id: "outlined-point-type",

                      }}
                    >
                      <option>Selecione</option>
                      <option defaultValue={descont.type_coupon === 'FRETE' ? true : false} value="freight">Desconto de Frete</option>
                      <option defaultValue={descont.type_coupon === 'TAXA' ? true : false} value="tax">Desconto de Taxa Scamb</option>
                    </Select>
                  </FormControl>
                </Grid> */}

                <Grid item md={6} xs={12}>
                  <TextField
                    id="outlined-login"
                    label="Data: Inicial"
                    type="date"
                    variant="outlined"
                    size="small"
                    inputRef={registerEditTax({
                      required: true,
                    })}
                    name="tax_min_date"
                    defaultValue={String(format(new Date(descont.started_date), 'yyyy-MM-dd'))}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <TextField
                    id="outlined-login"
                    label="Data: Final"
                    type="date"
                    variant="outlined"
                    size="small"
                    inputRef={registerEditTax({
                      required: true,
                    })}
                    name="tax_max_date"
                    defaultValue={String(format(new Date(descont.expired_date), 'yyyy-MM-dd'))}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item md={12} xs={12}>
                  <TextField
                    id="outlined-login"
                    label="Porcentagem de desconto nos Pontos *"
                    type="number"
                    variant="outlined"
                    InputProps={{ inputProps: { min: 0, max: 100 } }}
                    size="small"
                    inputRef={registerEditTax({
                      required: true,
                    })}
                    inputProps={{
                      maxLength: 3,
                    }}
                    onChange={({ target }) => setMaxRate(target.value)}
                    name="pontos_percentage"
                    fullWidth
                    defaultValue={descont.pontos_percentage}
                  />
                </Grid>

                <Grid item md={12} xs={12}>
                  <TextField
                    id="outlined-login"
                    label="Porcentagem de desconto na Taxa *"
                    type="number"
                    variant="outlined"
                    InputProps={{ inputProps: { min: 0, max: 100 } }}
                    size="small"
                    inputRef={registerEditTax({
                      required: true,
                    })}
                    inputProps={{
                      maxLength: 3,
                    }}
                    onChange={({ target }) => setMaxRate(target.value)}
                    name="taxa_percentage"
                    fullWidth
                    defaultValue={descont.taxa_percentage}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    id="outlined-login"
                    label="Porcentagem de desconto no Frete *"
                    type="number"
                    variant="outlined"
                    InputProps={{ inputProps: { min: 0, max: 100 } }}
                    size="small"
                    inputRef={registerEditTax({
                      required: true,
                    })}
                    inputProps={{
                      maxLength: 3,
                    }}
                    onChange={({ target }) => setMaxRate(target.value)}
                    name="frete_percentage"
                    fullWidth
                    defaultValue={descont.frete_percentage}
                  />
                </Grid>

                <Grid item md={12} xs={12}>
                  <InputLabel shrink={true} id="demo-simple-select-outlined-label">
                    Descrição *
                  </InputLabel>
                  <FormControl variant="outlined" size="small" fullWidth>
                    <textarea
                      maxLength="100"
                      style={{ resize: 'none', borderColor: '#eaeaea', borderRadius: '6px' }}
                      native
                      rows="3"
                      name="description"
                      defaultValue={descont.description}
                      ref={registerEditTax({
                        required: true
                      })}
                      inputProps={{
                        name: "tax_description",
                        id: "outlined-transaction"
                      }}
                    >
                    </textarea>
                  </FormControl>
                </Grid>

              </Grid>

              <Grid item md={12} xs={12}>
                <FormControlLabel
                  control={<Switch checked={check.pause_adjustment} onChange={handleChangeCheckbox} name="pause_adjustment" color="primary" />}
                  label="Desativar ajuste"
                />

              </Grid>

              <Button
                className={"btn-step " + classes.center}
                color="primary"
                autoFocus
                type="submit"
                style={{ height: matches ? 35 : 40 }}
              >
                Concluir
            </Button>

            </form>
          )}
        </div>
      </ModalDialog>
    </>
  );
}

export default Scambers;
