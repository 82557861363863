//===============================================================================================================
// #1 - Import * Base
//===============================================================================================================
import React, { useRef, useEffect, useState } from "react";

//===============================================================================================================
// #5 - Import * Sweet Alert
//===============================================================================================================
import SweetAlert from 'sweetalert';
import Swal from 'sweetalert2';

//===============================================================================================================
// #1 - Import * React Hook Form
//===============================================================================================================
import { useForm } from 'react-hook-form';
//===============================================================================================================
// #1 - Import * Bootstrap
//===============================================================================================================
import { Table, Button } from "react-bootstrap";

//===============================================================================================================
// #1 - Import * Material UI Components
//===============================================================================================================
import {
  Grid,
  TextField,
  Select,
  useMediaQuery,
  makeStyles,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import SubjectIcon from "@material-ui/icons/Subject";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Pagination from '@material-ui/lab/Pagination';

//===============================================================================================================
// #7 - Import * FontAwesome Icons
//===============================================================================================================
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";

import OrderService from './../../services/Order';
import OrderShoppingService from './../../services/OrderShopping';
import CancelOrder from './../../services/CancelOrderShopping';
import Download from './../../services/Dowload';

import ModalDialog from "../../components/ModalDialog";

//===============================================================================================================
// #1 - Material UI Components - Styles
//===============================================================================================================
const useStyles = makeStyles({
  center: {
    margin: "auto",
  },
  resize: {
    fontSize: "10px",
  },
  inputSize: {
    height: 50,
  },
});

function Orders() {

  // * Refs
  const refModalDialog = useRef();
  const refModalDetail = useRef();
  const refModalItems = useRef();

  // * Styles
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:600px)");
  const limitofData = 20;

  const [listOrders, setListOrders] = useState([])
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [scamberType, setScamberType] = useState("scamber_comprador");
  const [filterEmail, setFilterEmail] = useState();
  const [selectedOrder, setSelectedOrder] = useState();
  const [pagination, setPagination] = useState();

  // * React Hook Form
  const { handleSubmit, register, errors, reset } = useForm();

  // * Form - onSubmit
  const onSubmit = (data) => {
    console.log(data, 'data')

    const template = (`<div class="swall-informations">
      <p><strong>Alterar status do pedido:</strong> <span>${data.order_status}</span></p> 
    </div>
    `);

    SweetAlert({
      html: true,
      title: "Confirmar a operação?",
      icon: "info",
      buttons: ["Não, cancelar", "Sim, pontuar"],
      dangerMode: true,
      content: {
        element: 'div',
        attributes: {
          innerHTML: `${template}`,
        },
      }

    }).then(function (isConfirm) {
      if (isConfirm) {
        const orderService = new OrderService();
        const orderShoppingService = new OrderShoppingService();
        const cancelOrder = new CancelOrder();
        refModalDialog.current.closeModal();

        if (data.order_status === 'FINALIZADO') {
          Swal.fire('Finalizando Pedido ... Aguarde');
          Swal.showLoading();
          console.log(selectedOrder);
          orderShoppingService.orderFinish({ order: selectedOrder._id, scamber: selectedOrder.scamber_comprador[0]._id }).then(() => {

            Swal.fire({
              title: "",
              text: "Pedido finalizado com sucesso!",
              type: "success",
            }).then(() => {
              loadOrders(startDate, endDate, scamberType, filterEmail);
            })

          }).catch((error) => {
            Swal.fire({
              type: "info",
              text: orderShoppingService.formatErrorMessage(error.response),
              title: "",
            });
          })
        } else if(data.order_status === 'CANCELADO') {
          cancelOrder.reversalOrder({ orderId: selectedOrder._id, scamber: selectedOrder.scamber_comprador[0]._id }).then(() => {
            Swal.fire({
              title: "",
              text: "Pedido Cancelado com sucesso!",
              type: "success",
            }).then(() => {
              loadOrders(startDate, endDate, scamberType, filterEmail);
            })
          }).catch((error) => {
            Swal.fire({
              type: "info",
              text: orderService.formatErrorMessage(error.response),
              title: "",
            });
          });
        }

        // * Close Modal
        reset({});

      } else {
        SweetAlert.close();
      }
    });
  };

  const loadOrders = (initialDate, endDate, nameFilter, filter, page) => {
    const orderService = new OrderService();

    Swal.fire('Carregando lista ... Aguarde');
    Swal.showLoading();
    orderService.loadOrders(initialDate, endDate, nameFilter, filter, 'shopping', page, limitofData).then((response) => {
      Swal.close();
      setListOrders(response.data.payload.result);
      setPagination(response.data.payload.pagination);
    }).catch((error) => {
      Swal.fire({
        type: "info",
        text: orderService.formatErrorMessage(error.response),
        title: "",
      });
    })
  }

  const handlePageChange = (page) => {
    loadOrders(startDate, endDate, scamberType, filterEmail, page);
  };

  const getOrdersFile = () => {
    const download = new Download();

    Swal.fire('Gerando arquivo ... Aguarde');
    Swal.showLoading();
    download.getOrderFile(startDate, endDate, scamberType, filterEmail).then((response) => {
      var fileURL = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv;charset=utf-8;' }));
      var fileLink = document.createElement('a');
      const newDate  = new Date();

      fileLink.href = fileURL;
      fileLink.setAttribute('download', `pedidos_${newDate.getDate()}_${newDate.getMonth() + 1}_${newDate.getFullYear()}.csv`);
      document.body.appendChild(fileLink);
      
      Swal.close();
      fileLink.click();
    }).catch(error => {
      Swal.close();
      Swal.fire({
        type: "info",
        html: download.formatErrorMessage(error.response),
        title: "",
      });
    });
  }

  const onFilter = (e) => {
    e.preventDefault();
    const actualDate = new Date();
    const ActualstartDate = actualDate.getFullYear() + '-01-01';
    const ActualendDate = actualDate.getFullYear() + '-12-31';


    loadOrders(startDate ? startDate : ActualstartDate, endDate ? endDate : ActualendDate, scamberType, filterEmail);
  }

  useEffect(() => {
    const actualDate = new Date();
    const ActualstartDate = actualDate.getFullYear() + '-01-01';
    const ActualendDate = actualDate.getFullYear() + '-12-31';

    loadOrders(ActualstartDate, ActualendDate, scamberType, filterEmail);
  }, [])

  let formatterDate = (date) => {
    var data = new Date(date),
      dia = data.getDate().toString(),
      diaF = (dia.length == 1) ? '0' + dia : dia,
      mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
      mesF = (mes.length == 1) ? '0' + mes : mes,
      anoF = data.getFullYear(),
      hora = data.getUTCHours().toString(),
      horaF = (hora.length == 1) ? '0' + hora : hora,
      minuto = data.getMinutes().toString(),
      minutoF = (minuto.length == 1) ? '0' + minuto : minuto,
      segundo = data.getSeconds().toString(),
      segundoF = (segundo.length == 1) ? '0' + segundo : segundo;

    return diaF + "/" + mesF + "/" + anoF + " " + horaF + ":" + minutoF + ":" + segundoF;
  }

  const formartPhoneNumber = (phoneNumber) => {
    const ddd = phoneNumber?.ddd;
    const numero = phoneNumber?.numero;
    const formatNumer = ddd && numero ? `(${ddd}) ${numero}` : '-';

    return formatNumer;
  }

  const clearFilter = (e) => {
    e.preventDefault();
    const actualDate = new Date();
    const ActualstartDate = actualDate.getFullYear() + '-01-01';
    const ActualendDate = actualDate.getFullYear() + '-12-31';

    setStartDate('');
    setEndDate('');
    setFilterEmail('');
    
    loadOrders(ActualstartDate, ActualendDate, scamberType, filterEmail);
  }

  return (
    <>
      <main role="main" id="view_scambers">
        <div className="_fluid-container">

          <div className="_page-section">
            <div className="icon">
              <SubjectIcon />
            </div>
            <div className="title">Pedidos</div>
          </div>

          <div className="_wrap-filter-activity">
            <h2 className="title">Filtros</h2>
            <div className="_filter-activity">
              <form className="_form" onSubmit={(e) => onFilter(e)}>
                <div className="_wrap-dates">
                  <div className="_wrap-input-group">
                    <label className="_title-label-input">Data inicial</label>
                    <div className="input-group">
                      <TextField
                        type="date"
                        className="form-control"
                        name="startDate"
                        placeholder="00/00/0000"
                        aria-describedby="cc-name"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="_wrap-input-group">
                    <label className="_title-label-input">Data final</label>
                    <div className="input-group">
                      <TextField
                        type="date"
                        className="form-control"
                        name="endDate"
                        placeholder="00/00/0000"
                        aria-describedby="cc-name"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="_wrap-input-group">
                    <label className="_title-label-input">Filtrar</label>
                    <div className="input-group">
                      <TextField
                        type="text"
                        className="form-control"
                        name="filterEmail"
                        aria-describedby="cc-name"
                        value={filterEmail}
                        onChange={(e) => setFilterEmail(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="_switch">
                  <div className="_option">
                    <label htmlFor="filter-type-1">
                      <input type="radio" id="filter-type-1" name="scamberType" defaultChecked={true} value="scamber_comprador" onChange={(e) => setScamberType(e.target.value)} />
                      <div className="mark">
                        <Icon icon={faCheck} />
                      </div>
                      <div className="title">
                        Comprador
                      </div>
                    </label>
                  </div>

                  <div className="_option">
                    <label htmlFor="filter-type-2">
                      <input type="radio" id="filter-type-2" name="scamberType" value="scamber_vendedor" onChange={(e) => setScamberType(e.target.value)} />
                      <div className="mark">
                        <Icon icon={faCheck} />
                      </div>
                      <div className="title">
                        Vendedor
                      </div>
                    </label>
                  </div>
                </div>

                <div className="_wrap-input-group _submit">
                  <button type="submit" className="submit-filter">
                    <SearchIcon></SearchIcon>
                  </button>
                </div>
              </form>
              <div className="excel-div">
                <button onClick={(e) => clearFilter(e)} className="excel-button">Limpar Filtros</button>
              </div>
              {/* <div className="excel-div">
                <button onClick={getOrdersFile} className="excel-button">Exportar .csv</button>
              </div> */}
            </div>
          </div>

          <Table bordered hover size="lg">
            <thead>
              <tr>
                <th>Nº do Pedido</th>
                <th>Nome Vendedor</th>
                <th>Nome e sobrenome vendedor</th>
                <th>Scamber vendedor</th>
                <th>Nome comprador</th>
                <th>Nome e sobrenome comprador</th>
                <th>Scamber comprador</th>
                <th>Telefone</th>
                <th>Valor Anuncios</th>
                <th>Valor pointsBack</th>
                <th>Valor frete</th>
                <th>Valor Total</th>
                <th>Pontos Comprados</th>
                <th>Operação logistico</th>
                <th>Status do pedido</th>
                <th>Forma de Pagamento</th>
                <th>Data do pedido</th>
                <th>Hora do pedido</th>
                <th>Link etiqueta</th>
                <th>Detalhes dos Produtos</th>
                <th>Detalhes do Pedido</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {listOrders.map((order, index) => {

                return (<tr key={index}>
                  <td>{order._id}</td>
                  <td>{order.scamber_vendedor && order.scamber_vendedor.length > 0 ? order.scamber_vendedor[0].nome.split(' ')[0] : '-'}</td>
                  <td>{order.scamber_vendedor && order.scamber_vendedor.length > 0 ? order.scamber_vendedor[0].nome : '-'}</td>
                  <td>{order.scamber_vendedor && order.scamber_vendedor.length > 0 ? order.scamber_vendedor[0].email : '-'}</td>
                  <td>{order.scamber_vendedor && order.scamber_comprador.length > 0 ? order.scamber_comprador[0].nome.split(' ')[0] : '-'}</td>
                  <td>{order.scamber_vendedor && order.scamber_comprador.length > 0 ? order.scamber_comprador[0].nome : '-'}</td>
                  <td>{order.scamber_comprador && order.scamber_comprador.length > 0 ? order.scamber_comprador[0].email :
                    order.scamber && order.scamber.length > 0 ? order.scamber[0].email : '-'}</td>
                  <td>{order.scamber_vendedor && order.scamber_comprador.length > 0 ? formartPhoneNumber(order.scamber_comprador[0].telefone[0]) : '-'}</td>
                  <td>{parseFloat(order.valor_total_anuncios).toFixed(2)}</td>
                  <td>{order.valor_total_cashback}</td>
                  <td>{parseFloat(order.valor_frete ? order.valor_frete : 0).toFixed(2)}</td>
                  <td>{parseFloat(order.valor).toFixed(2)}</td>
                  <td>{order.valor_pontos_faltante ? order.valor_pontos_faltante : 'Não houve compra de Pontos' }</td>
                  {order.tipo_entrega ? (order.tipo_entrega === 'IN_HANDS' ? <td>Em mãos</td> : <td>Correrios</td>) : ' - '}
                  <td>{order.status_pedido === 'FINALIZADO' ? order.vendedor_creditado === false ? 'AGUARDANDO CONFIRMAÇÃO DE RECEBIMENTO' : order.status_pedido : order.status_pedido}</td>
                  <td>{order.tipo_forma_pagamento}</td>
                  <td>{formatterDate(order.data_inclusao).split(' ')[0]}</td>
                  <td>{formatterDate(order.data_inclusao).split(' ')[1]}</td>
                  <td>{order.url_etiquetas? <a href={order.url_etiquetas} target="_blank">Visualizar Etiqueta</a>: ''}</td>
                  <td>
                    <Button
                      className={"btn-step btn-edit-table"}
                      color="primary"
                      autoFocus
                      style={{ height: matches ? 45 : 50 }}
                      onClick={() => { setSelectedOrder(order); refModalItems.current.openModal() }}
                    >
                      Ver Produtos
                        {/*<AddIcon fontSize="inherit" />*/}
                    </Button>
                  </td>
                  <td>
                    <Button
                      className={"btn-step btn-edit-table"}
                      color="primary"
                      autoFocus
                      style={{ height: matches ? 45 : 50 }}
                      onClick={() => { setSelectedOrder(order); refModalDetail.current.openModal() }}
                    >
                      Ver Detalhes
                        {/*<AddIcon fontSize="inherit" />*/}
                    </Button>
                  </td>
                  <td>
                    <Button
                      className={"btn-step btn-edit-table"}
                      color="primary"
                      autoFocus
                      style={{ height: matches ? 45 : 50 }}
                      onClick={() => { setSelectedOrder(order); refModalDialog.current.openModal() }}
                    >
                      Alterar Status
                        {/*<AddIcon fontSize="inherit" />*/}
                    </Button>
                  </td>
                </tr>)
              })}

            </tbody>
          </Table>
        </div>
        <div class="pagination-style" style={{ marginTop: '10px'}}>
          {pagination && pagination.totalPages ? (
            <Pagination
              shape="rounded"
              count={pagination.totalPages}
              variant="outlined"
              color="primary"
              hidePrevButton
              hideNextButton
              size="large"
              onChange={(_, p) => handlePageChange(p)}
            />
          ) : undefined}
        </div>
      </main>

      <ModalDialog ref={refModalDialog} dialogTitle="Ações" onCloseModal={<button type="button" className="btn" onClick={() => { reset({}); refModalDialog.current.closeModal() }}> {matches ? <CloseIcon /> : <ChevronLeftIcon />} </button>}>
        <div className="_wrap-form">
          <form className="_form" onSubmit={handleSubmit(onSubmit)}>
            <h1 className="_content-title">Selecione uma ação</h1>
            <Grid container spacing={2}>

              <Grid item md={12} xs={12}>
                <FormControl variant="outlined" size="small" fullWidth>
                  <InputLabel shrink={true} id="change-order-status--outlined-label">
                    Alterar status do pedido
                  </InputLabel>
                  <Select
                    native
                    label="Age"
                    name="status"
                    inputRef={register({
                      required: true
                    })}
                    inputProps={{
                      name: "order_status",
                      id: "outlined-point-type",

                    }}
                  >
                    <option value="">Selecione</option>
                    <option value="FINALIZADO">Finalizado</option>
                    <option value="CANCELADO">Cancelado</option>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Button
              className={"btn-step " + classes.center}
              color="primary"
              autoFocus
              type="submit"
              style={{ height: matches ? 35 : 40 }}
            >
              Concluir
            </Button>
          </form>
        </div>
      </ModalDialog>


      <ModalDialog ref={refModalDetail} dialogTitle="Detalhes" onCloseModal={<button type="button" className="btn" onClick={() => { reset({}); refModalDetail.current.closeModal() }}> {matches ? <CloseIcon /> : <ChevronLeftIcon />} </button>}>
        <div className="_wrap-form">

          <div>
            <p style={{ paddingBottom: '15px', paddingTop: '15px', marginBottom: 0 }}><strong>Endereço Entrega</strong></p>
            <p style={{ marginBottom: 0 }}><strong>CEP:</strong> {selectedOrder?.endereco_entrega?.cep}</p>
            <p style={{ marginBottom: 0 }}><strong>Logradouro:</strong> {selectedOrder?.endereco_entrega?.logradouro}</p>
            <p style={{ marginBottom: 0 }}><strong>Número:</strong> {selectedOrder?.endereco_entrega?.numero}</p>
            <p style={{ marginBottom: 0 }}><strong>Bairro:</strong> {selectedOrder?.endereco_entrega?.bairro}</p>
            <p style={{ marginBottom: 0 }}><strong>Cidade:</strong> {selectedOrder?.endereco_entrega?.cidade}</p>
            <p style={{ marginBottom: 0 }}><strong>Estado:</strong> {selectedOrder?.endereco_entrega?.estado}</p>
            <p><strong>Complemento:</strong> {selectedOrder?.endereco_entrega?.complemento}</p>
          </div>

          <div>
            <p style={{ paddingBottom: '15px', paddingTop: '15px', marginBottom: 0 }}><strong>Endereço Vendedor</strong></p>
            <p style={{ marginBottom: 0 }}><strong>CEP:</strong> {selectedOrder?.endereco_vendedor?.cep}</p>
            <p style={{ marginBottom: 0 }}><strong>Logradouro:</strong> {selectedOrder?.endereco_vendedor?.logradouro}</p>
            <p style={{ marginBottom: 0 }}><strong>Número:</strong> {selectedOrder?.endereco_vendedor?.numero}</p>
            <p style={{ marginBottom: 0 }}><strong>Bairro:</strong> {selectedOrder?.endereco_vendedor?.bairro}</p>
            <p style={{ marginBottom: 0 }}><strong>Cidade:</strong> {selectedOrder?.endereco_vendedor?.cidade}</p>
            <p style={{ marginBottom: 0 }}><strong>Estado:</strong> {selectedOrder?.endereco_vendedor?.estado}</p>
            <p style={{ marginBottom: 0 }}><strong>Complemento:</strong> {selectedOrder?.endereco_vendedor?.complemento}</p>
          </div>

        </div>
      </ModalDialog>

      <ModalDialog ref={refModalItems} dialogTitle="Produtos" onCloseModal={<button type="button" className="btn" onClick={() => { reset({}); refModalItems.current.closeModal() }}> {matches ? <CloseIcon /> : <ChevronLeftIcon />} </button>}>
        <div className="_wrap-form">

          {selectedOrder?.itens.map(item => (
            <div key={item._id}>
              <p style={{ paddingTop: '15px', marginBottom: 0 }}><strong>Produto: </strong><a href={item.url_encurtada} target="_black">{item.titulo}</a></p>
              <p style={{ marginBottom: 0 }}><strong>Descrição: </strong> {item.descricao}</p>
              <p style={{ marginBottom: 0 }}><strong>Valor: </strong> {parseFloat(item.valor_por).toFixed(2)}</p>
            </div>
          ))}
        </div>
      </ModalDialog>
    </>
  );
}

export default Orders;
