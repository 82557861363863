import React, { useRef, useEffect, useState } from "react";
import Swal from 'sweetalert2';
import SweetAlert from 'sweetalert'; 
import { Button } from "react-bootstrap";
import {
  TextField,
} from "@material-ui/core";
import SubjectIcon from "@material-ui/icons/Subject";
import SearchIcon from "@material-ui/icons/Search";
import Pagination from '@material-ui/lab/Pagination';
import CloseIcon from "@material-ui/icons/Close";

import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";

import OrderService from '../../../services/Order';
import Download from '../../../services/Dowload';
import PlugNotas from "../../../services/PlugNotas";
import CancelOrders from "../../../services/CancelOrders";

import { Accordion } from "../../../components/ui/category-accordion";
import ModalDialog from "../../../components/ModalDialog";

import { WrapperPage } from "./styles";

import { ResultBlock } from "./components/result-block/block";
import { ResultBlockTags, ButtonContentTag } from "./components/result-block/tags";
import { ResultBlockActions, ButtonAction } from "./components/result-block/actions";

function Orders() {
  const refModalItems = useRef();
  const limitofData = 20;

  const [listOrders, setListOrders] = useState([])
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [scamberType, setScamberType] = useState("scamber_comprador");
  const [filterEmail, setFilterEmail] = useState();
  const [pagination, setPagination] = useState();
  const [selectedOrder, setSelectedOrder] = useState();

  const loadOrders = (initialDate, endDate, nameFilter, filter, page) => {
    const orderService = new OrderService();

    Swal.fire('Carregando lista ... Aguarde');
    Swal.showLoading();
    orderService.loadOrders(initialDate, endDate, nameFilter, filter, 'pdv', page, limitofData).then((response) => {
      Swal.close();
      setListOrders(response.data.payload.result);
      setPagination(response.data.payload.pagination);
    }).catch((error) => {
      Swal.fire({
        type: "info",
        text: orderService.formatErrorMessage(error.response),
        title: "",
      });
    })
  }

  const handlePageChange = (page) => {
    loadOrders(startDate, endDate, scamberType, filterEmail, page);
  };

  const formatterDate = (date) => {
    var data = new Date(date),
      dia = data.getDate().toString(),
      diaF = (dia.length == 1) ? '0' + dia : dia,
      mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
      mesF = (mes.length == 1) ? '0' + mes : mes,
      anoF = data.getFullYear(),
      hora = data.getUTCHours().toString(),
      horaF = (hora.length == 1) ? '0' + hora : hora,
      minuto = data.getMinutes().toString(),
      minutoF = (minuto.length == 1) ? '0' + minuto : minuto,
      segundo = data.getSeconds().toString(),
      segundoF = (segundo.length == 1) ? '0' + segundo : segundo;

    return diaF + "/" + mesF + "/" + anoF + " " + horaF + ":" + minutoF + ":" + segundoF;
  }

  const getOrdersFile = () => {
    const download = new Download();

    Swal.fire('Gerando arquivo ... Aguarde');
    Swal.showLoading();
    download.getOrderFile(startDate, endDate, scamberType, filterEmail, 'scamb').then((response) => {
      var fileURL = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv;charset=utf-8;' }));
      var fileLink = document.createElement('a');
      const newDate = new Date();

      fileLink.href = fileURL;
      fileLink.setAttribute('download', `pedidos_${newDate.getDate()}_${newDate.getMonth() + 1}_${newDate.getFullYear()}.csv`);
      document.body.appendChild(fileLink);

      Swal.close();
      fileLink.click();
    }).catch(error => {
      Swal.close();
      Swal.fire({
        type: "info",
        html: download.formatErrorMessage(error.response),
        title: "",
      });
    });
  }

  const onFilter = (e) => {
    e.preventDefault();
    const actualDate = new Date();
    const ActualstartDate = actualDate.getFullYear() + '-01-01';
    const ActualendDate = actualDate.getFullYear() + '-12-31';


    loadOrders(startDate ? startDate : ActualstartDate, endDate ? endDate : ActualendDate, scamberType, filterEmail);
  }

  useEffect(() => {
    const actualDate = new Date();
    const ActualstartDate = actualDate.getFullYear() + '-01-01';
    const ActualendDate = actualDate.getFullYear() + '-12-31';

    loadOrders(ActualstartDate, ActualendDate, scamberType, filterEmail);
  }, [])

  const clearFilter = (e) => {
    e.preventDefault();
    const actualDate = new Date();
    const ActualstartDate = actualDate.getFullYear() + '-01-01';
    const ActualendDate = actualDate.getFullYear() + '-12-31';

    setStartDate('');
    setEndDate('');
    setFilterEmail('');

    loadOrders(ActualstartDate, ActualendDate, scamberType, filterEmail);
  }

  const cancelOrder = (orderID) => {
    SweetAlert({
      title: "Cancelar Pedido",
      text: `Seu pedido está sendo cancelado, confirmar ação ?`,
      icon: "warning",
      dangerMode: true,
      buttons: ["Cancelar", "Confirmar"],
    }).then(function (isConfirm) {
      if (isConfirm) {
        Swal.fire('Efetuando o cancelamento... Aguarde');
        Swal.showLoading();

        const cancelOrderService = new CancelOrders();
        cancelOrderService.reversalOrder(orderID).then((response) => {
          if (response.data && response.data.type === 'success') {
            Swal.fire({ type: "success", html: 'Pedido cancelado com sucesso!', title: "" }).then(() => {
              loadOrders(startDate, endDate, scamberType, filterEmail, 1);
              setPagination(1);
            });
          } else {
            Swal.fire({
              type: "info",
              html: response.data.message,
              title: "",
            });
          }
        }).catch((error) => {
          Swal.fire({
            type: "info",
            html: cancelOrderService.formatErrorMessage(error.response, 'COODLO'),
            title: "",
          });
        })
      }
    });
  }

  const finishOrder = (orderID) => {
    SweetAlert({
      title: "Confirmar Pagamento",
      text: `Seu pedido está sendo confirmado, confirmar ação ?`,
      icon: "warning",
      dangerMode: true,
      buttons: ["Cancelar", "Confirmar"],
    }).then(function (isConfirm) {
      if (isConfirm) {
        Swal.fire('Efetuando a confirmação do pagamento... Aguarde');
        Swal.showLoading();
        const orderService = new OrderService();
        orderService.orderFinish({ order: orderID }).then((response) => {
          if (response.data && response.data.type === 'success') {
            Swal.fire({ type: "success", html: 'Pagamento confirmado com sucesso!', title: "" }).then(() => {
              loadOrders(startDate, endDate, scamberType, filterEmail, 1);
              setPagination(1);
            });
            
          } else {
            Swal.fire({
              type: "info",
              html: response.data.message,
              title: "",
            });
          }
        }).catch((error) => {
          Swal.fire({
            type: "info",
            html: orderService.formatErrorMessage(error.response, 'FNLODLO'),
            title: "",
          });
        })
      }
    });
  }

  const handlePlugNotas = (orderID) => {
    const nota = new PlugNotas();

    nota.generateSimpleInvoice(orderID).then((response) => {
      window.open('about:blank', "_blank").document.write(response.data);
    }).catch((error) => {
      Swal.fire({
        type: "info",
        html: nota.formatErrorMessage(error.response, 'HPNLO'),
        title: "",
      });
    })
  }

  const resendEmailInvoice = (orderID) => {
    const nota = new PlugNotas();

    nota.generateSimpleInvoice(orderID).then((response) => {
      Swal.fire('', 'Email com a nota do pedido reenviado com sucesso!', 'success');
    }).catch((error) => {
      Swal.fire({
        type: "info",
        html: nota.formatErrorMessage(error.response, 'REILO'),
        title: "",
      });
    })
  }

  return (
    <>
      <main role="main" id="view_scambers">
        <div className="_fluid-container">

          <div className="_page-section">
            <div className="icon">
              <SubjectIcon />
            </div>
            <div className="title">Pedidos</div>
          </div>

          <div className="_wrap-filter-activity">
            <h2 className="title">Filtros</h2>
            <div className="_filter-activity">
              <form className="_form" onSubmit={(e) => onFilter(e)}>
                <div className="_wrap-dates">
                  <div className="_wrap-input-group">
                    <label className="_title-label-input">Data inicial</label>
                    <div className="input-group">
                      <TextField
                        type="date"
                        className="form-control"
                        name="startDate"
                        placeholder="00/00/0000"
                        aria-describedby="cc-name"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="_wrap-input-group">
                    <label className="_title-label-input">Data final</label>
                    <div className="input-group">
                      <TextField
                        type="date"
                        className="form-control"
                        name="endDate"
                        placeholder="00/00/0000"
                        aria-describedby="cc-name"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="_wrap-input-group">
                    <label className="_title-label-input">Filtrar</label>
                    <div className="input-group">
                      <TextField
                        type="text"
                        className="form-control"
                        name="filterEmail"
                        aria-describedby="cc-name"
                        value={filterEmail}
                        onChange={(e) => setFilterEmail(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                {/* <div className="_switch">
                  <div className="_option">
                    <label htmlFor="filter-type-1">
                      <input type="radio" id="filter-type-1" name="scamberType" defaultChecked={true} value="scamber_comprador" onChange={(e) => setScamberType(e.target.value)} />
                      <div className="mark">
                        <Icon icon={faCheck} />
                      </div>
                      <div className="title">
                        Comprador
                      </div>
                    </label>
                  </div>

                  <div className="_option">
                    <label htmlFor="filter-type-2">
                      <input type="radio" id="filter-type-2" name="scamberType" value="scamber_vendedor" onChange={(e) => setScamberType(e.target.value)} />
                      <div className="mark">
                        <Icon icon={faCheck} />
                      </div>
                      <div className="title">
                        Vendedor
                      </div>
                    </label>
                  </div>
                </div> */}

                <div className="_wrap-input-group _submit">
                  <button type="submit" className="submit-filter">
                    <SearchIcon></SearchIcon>
                  </button>
                </div>
              </form>
              <div className="excel-div">
                <button onClick={(e) => clearFilter(e)} className="excel-button">Limpar Filtros</button>
              </div>
              {/* <div className="excel-div">
                <button onClick={getOrdersFile} className="excel-button">Exportar .csv</button>
              </div> */}
            </div>
          </div>
        </div>

        <WrapperPage>
          <div className="_fluid-container">

            {
              listOrders.map((order, index) => (

                <ResultBlock numberCount={index + 1} key={order._id} orderId={order._id} buyerName={order.scamber_comprador[0].nome} orderDate={formatterDate(order.data_inclusao).split(' ')[0]}>
                  <ResultBlockTags>
                    <ButtonContentTag tagKey={`Status Pedido`} value={order.status_pedido} />
                    <ButtonContentTag tagKey={`Valor em Pontos`} value={order.valor_pontos} />
                    <ButtonContentTag tagKey={`Valor Taxa`} value={`${parseFloat((order.valor_taxa !== undefined ? order.valor_taxa : order.taxa_valor)).toFixed(2)}`} />
                    <ButtonContentTag tagKey={`Valor Total`} value={`R$ ${parseFloat(order.valor).toFixed(2)}`} />
                  </ResultBlockTags>

                  <ResultBlockActions>
                    <ButtonAction
                      title="Produto"
                      text="Clique para visualizar o produto"
                      icon={<Icon icon={["far", "eye"]} />}
                      onClick={() => { setSelectedOrder(order); refModalItems.current.openModal() }}
                    />

                    <ButtonAction
                      title="Confirmar Pagamento"
                      text="Confirmar pagamento do produto"
                      icon={<Icon icon={["far", "check"]} />}
                      onClick={() => { setSelectedOrder(order); finishOrder(order._id); }}
                      disabled={order.status_pedido !== 'AGUARDANDO_PAGAMENTO' ? 'disabled' : ''}
                    />

                    <ButtonAction
                      title="Ver Nota"
                      text="Visualizar nota do pedido"
                      icon={<Icon icon={["far", "scroll"]} />}
                      onClick={() => { setSelectedOrder(order); handlePlugNotas(order._id) }}
                    />

                    <ButtonAction
                      title="Enviar Nota"
                      text="Para o email do comprador"
                      icon={<Icon icon={["far", "share"]} />}
                      onClick={() => { setSelectedOrder(order); resendEmailInvoice(order._id) }}
                    />

                    <ButtonAction
                      title="Cancelar Pedido"
                      text="Fazer cancelamento do pedido"
                      icon={<Icon icon={["far", "ban"]} />}
                      onClick={() => { setSelectedOrder(order); cancelOrder(order._id) }}
                      disabled={order.status_pedido !== 'AGUARDANDO_PAGAMENTO' ? 'disabled' : ''}
                    />

                  </ResultBlockActions>
                </ResultBlock>
              ))}

          </div>

          <div class="pagination-style" style={{ marginTop: '10px' }}>
          {pagination && pagination.totalPages ? (
            <Pagination
              shape="rounded"
              count={pagination.totalPages}
              variant="outlined"
              color="primary"
              hidePrevButton
              hideNextButton
              size="large"
              onChange={(_, p) => handlePageChange(p)}
            />
          ) : undefined}
        </div>
        </WrapperPage>
{/* 
        {listOrders.map((order) => (
          <div style={{ marginBottom: '10px' }}>
            <Accordion
              orderId={order._id}
              buyer={order.scamber_comprador[0].nome}
              orderDate={formatterDate(order.data_inclusao).split(' ')[0]}
            >
              <div key={order._id}>
                <div style={{ padding: '10px' }}>
                  <strong><h6 style={{ fontWeight: 'bold' }}>Nº Pedido</h6></strong>
                  <p>{order._id}</p>
                </div>

                <div style={{ padding: '10px' }}>
                  <strong><h6 style={{ fontWeight: 'bold' }}>Status do Pedido</h6></strong>
                  <p>{order.status_pedido}</p>
                </div>

                <div style={{ padding: '10px' }}>
                  <strong><h6 style={{ fontWeight: 'bold' }}>Data da Compra</h6></strong>
                  <p>{formatterDate(order.data_inclusao)}</p>
                </div>

                <div style={{ padding: '10px' }}>
                  <strong><h6 style={{ fontWeight: 'bold' }}>Comprador</h6></strong>
                  <p>{order.scamber_comprador[0].nome}</p>
                </div>

                <div style={{ padding: '10px' }}>
                  <strong><h6 style={{ fontWeight: 'bold' }}>Valor em Pontos</h6></strong>
                  <p>{order.valor_pontos}</p>
                </div>

                <div style={{ padding: '10px' }}>
                  <strong><h6 style={{ fontWeight: 'bold' }}>Valor Taxa</h6></strong>
                  <p>{`${parseFloat((order.valor_taxa !== undefined ? order.valor_taxa : order.taxa_valor)).toFixed(2)}`}</p>
                </div>

                <div style={{ padding: '10px' }}>
                  <strong><h6 style={{ fontWeight: 'bold' }}>Valor Total</h6></strong>
                  <p>{`${parseFloat(order.valor_taxa !== undefined ? (order.valor_frete + order.valor_taxa) : order.taxa_valor).toFixed(2)}`}</p>
                </div>

                <div style={{ padding: '10px' }}>
                  <strong><h6>Produtos</h6></strong>
                  <Button
                    color="primary"
                    autoFocus
                    onClick={() => { setSelectedOrder(order); refModalItems.current.openModal() }}
                  >
                    Ver Produtos
                  </Button>
                </div>

                <div style={{ padding: '10px', display: 'flex', flexDirection: 'column' }} className='actions'>
                  <strong style={{ marginBottom: '10px' }}><h6>Ações</h6></strong>


                  <Button
                    disabled={order.status_pedido !== 'AGUARDANDO_PAGAMENTO' ? 'disabled' : ''}
                    style={{ marginBottom: '10px' }}
                    color="success"
                    variant="success"
                    autoFocus
                    onClick={() => { setSelectedOrder(order); finishOrder(order._id); }}
                  >
                    Confirmar Pagamento
                  </Button>

                  <Button
                    style={{ marginBottom: '10px' }}
                    color="primary"
                    variant="secondary"
                    autoFocus
                    onClick={() => { setSelectedOrder(order); handlePlugNotas(order._id) }}
                  >
                    Ver nota do pedido
                  </Button>

                  <Button
                    style={{ marginBottom: '10px' }}
                    color="primary"
                    variant="secondary"
                    autoFocus
                    onClick={() => { setSelectedOrder(order); resendEmailInvoice(order._id) }}
                  >
                    Enviar nota para o email do comprador
                  </Button>

                  <Button
                    disabled={order.status_pedido !== 'AGUARDANDO_PAGAMENTO' ? 'disabled' : ''}
                    style={{ marginBottom: '10px' }}
                    color="primary"
                    variant="danger"
                    autoFocus
                    onClick={() => { setSelectedOrder(order); cancelOrder(order._id) }}
                  >
                    Cancelar o pedido
                  </Button>

                </div>

              </div>
            </Accordion>
          </div>
        ))} */}

        
      </main>

      <ModalDialog
        ref={refModalItems}
        dialogTitle="Produtos"
        onCloseModal={
          <button
            type="button"
            className="btn"
            onClick={() => { refModalItems.current.closeModal() }}
          >
            {<CloseIcon />}
          </button>
        }>
        <div className="_wrap-form">

          {selectedOrder?.itens.map(item => (
            <div key={item._id}>
              <p style={{ paddingTop: '15px', marginBottom: 0 }}><strong>Produto: </strong><a href={item.url_encurtada} target="_black">{item.titulo}</a></p>
              <p style={{ marginBottom: 0 }}><strong>Descrição: </strong> {item.descricao}</p>
              <p style={{ marginBottom: 0 }}><strong>Valor Pontos: </strong> {item.pontos_por.$numberDecimal}</p>
              <p style={{ paddingBottom: '15px', marginBottom: 0 }}><strong>Valor Taxa: </strong> {item?.taxa_scamb.$numberDecimal || 0}</p>
            </div>
          ))}
        </div>
      </ModalDialog>
    </>
  );
}

export default Orders;
