import React, { useState, useCallback, useEffect, useRef } from 'react';
import Swal from 'sweetalert2';
import { Button } from "react-bootstrap";
import { useForm } from 'react-hook-form';
import { TextField, Switch, Grid, InputAdornment, IconButton } from "@material-ui/core";
import SubjectIcon from '@material-ui/icons/Subject';
import SearchIcon from '@material-ui/icons/Search';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/pro-light-svg-icons";

import ScamberService from '../services/Scamber';

import ModalDialog from '../components/ModalDialog';
import { Accordion } from "../components/ui/category-accordion";

import { allAccess, scambMenu, shoppingMenu, pdvMenu } from '../data/Access';

function Access() {
  const [adminsUsers, setAdminsUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [userPermissions, setUserPermissions] = useState();
  const [userSelected, setUserSelected] = useState();
  const [password, setPassword] = useState(false);

  const refModalPermissions = useRef();
  const refModalCreateUser = useRef();
  const refModalChangePassword = useRef();

  const { handleSubmit, register, errors, reset } = useForm();
  const { handleSubmit: handleSumitNewPassword, register: registerNewPassword, errors: errorsNewPassword, reset: resetNewPassword } = useForm();

  const handleOpenModalPermissions = ({ user }) => {
    setUserSelected(user._id);
    setUserPermissions(user.permissoes);

    refModalPermissions.current.openModal();
  }

  const handleOpenModalCreateUser = () => {
    reset({});
    setUserPermissions();

    refModalCreateUser.current.openModal();
  }

  const handleOpenModalChangePassword = ({ user }) => {
    setUserSelected(user._id);
    resetNewPassword({});
    setPassword(false);

    refModalChangePassword.current.openModal();
  }

  const handleCreateAdminUser = ({ login, senha }) => {
    const scamberService = new ScamberService();

    scamberService.createAdminUser({ 
      login: login, 
      senha: senha, 
      permissoes: userPermissions 
    }).then((response) => {
      if(response.data.type === 'success') {
        Swal.fire({
          type: 'success',
          html: 'Usuário Criado com sucesso',
          title: '',
        })

        handleSearchAdminUser({ search: search });
      }
    }).catch((err) => {
      Swal.fire({
        type: 'error',
        html: scamberService.formatErrorMessage(err.response),
        title: '',
      });
    });
  }

  const handleUpdateAdminUser = ({ senha, permissoes }) => {
    const scamberService = new ScamberService();
    console.log(permissoes);

    scamberService.editAdminUser({ 
      userAdminId: userSelected, 
      senha: senha ? senha : undefined, 
      permissoes: permissoes ? permissoes : undefined
    }).then((response) => {
      if(response.data.type === 'success') {
        Swal.fire({
          type: 'success',
          html: 'Usuário atualizado com sucesso',
          title: '',
        }).then(() => {
          handleSearchAdminUser({ search: search });
        })        
      }
    }).catch((err) => {
      Swal.fire({
        type: 'error',
        html: scamberService.formatErrorMessage(err.response),
        title: '',
      });
    });
  }

  const handleSearchAdminUser = ({ search }) => {
    Swal.fire('Carregando Usuários ... Aguarde');
    const scamberService = new ScamberService();

    scamberService.findAdminUsers({ search: search }).then((response) => {
      setAdminsUsers(response.data.payload);
      Swal.close();
    }).catch((err) => {
      Swal.fire({
        type: 'error',
        html: scamberService.formatErrorMessage(err.response),
        title: '',
      });
    });
  };

  const handleChangeCheck = ({ menu, access }) => {
    const scambAccess = userPermissions ? userPermissions.scamb || [] : [];
    const shoppingAccess = userPermissions ? userPermissions.shopping : [];
    const pdvAccess = userPermissions ? userPermissions.pdv || [] : [];

    switch (menu) {
      case 'scamb': 
        const indexScamb = scambAccess.indexOf(access);
        indexScamb > -1 ? scambAccess.splice(indexScamb, 1) : scambAccess.push(access);
        break;
      case 'shopping':
        const indexshopping = shoppingAccess.indexOf(access);
        indexshopping > -1 ? shoppingAccess.splice(indexshopping, 1) : shoppingAccess.push(access);
        break;
      case 'pdv': 
        const indexPdv = pdvAccess.indexOf(access);
        indexPdv > -1 ? pdvAccess.splice(indexPdv, 1) : pdvAccess.push(access);
        break;
      default: 
        return undefined;
    }
    
    return setUserPermissions({ scamb: scambAccess, shopping: shoppingAccess, pdv: pdvAccess });
  }

  const handleCheck = ({ menu, access }) => {
    const scambAccess = userPermissions ? userPermissions.scamb|| [] : [];
    const shoppingAccess = userPermissions ? userPermissions.shopping || [] : [];
    const pdvAccess = userPermissions ? userPermissions.pdv || [] : [];

    switch (menu) {
      case 'scamb': 
        const checkScambAccess = scambAccess.find(scambAccess => scambAccess === access);
        return !!checkScambAccess ? true : false;
      case 'shopping':
        const checkshoppingAccess = shoppingAccess.find(scambAccess => scambAccess === access);
        return !!checkshoppingAccess ? true : false;
      case 'pdv': 
        const checkPdvAccess = pdvAccess.find(scambAccess => scambAccess === access);
        return !!checkPdvAccess ? true : false;
      default: 
        return false
    }
  }

  const clearFilter = () => {
    setSearch("");
    handleSearchAdminUser({}); 
  }

  const handleChangePassword = ({ senha }) => {
    handleUpdateAdminUser({ senha: senha });
    refModalChangePassword.current.closeModal();
  }

  const handleInitialValue = useCallback(() => {
    handleSearchAdminUser({});
  }, []);

  useEffect(() => {
    handleInitialValue();
  }, [handleInitialValue]);

  return (
    <>
      <main role="main" id="view_scambers">
        <div className="_fluid-container">
          <div className="_page-section">
            <div className="icon">
              <SubjectIcon />
            </div>
            <div className="title"> Acessos</div>
          </div>

          <div className="_wrap-filter-activity">
            <h2 className="title">Filtrar</h2>
            <div className="_filter-activity _form _search">
              <div className="_form _search" >
                <div className="form-group search">
                  <TextField
                    id="filterShowcase"
                    label="Filtrar"
                    variant="outlined"
                    size={"small"}
                    fullWidth
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ style: { height: '39px' } }}
                  />

                  <button onClick={() => handleSearchAdminUser({ search: search })}>
                    <SearchIcon></SearchIcon>
                  </button>
                </div>
              </div>

              <div className="excel-div">
                <button onClick={(e) => clearFilter(e)} className="excel-button">Limpar Filtros</button>
              </div>
              <div className="excel-div">
                <button className="excel-button" onClick={() => handleOpenModalCreateUser()}>Criar Usuário</button>
              </div>
            </div>
          </div>

          <table className="table table-striped">
            <thead>
              <tr>
                <th scope={"col"}>LOGIN</th>
                <th scope={"col"}>PERMISSÕES</th>
                <th scope={"col"}>ALTERAR SENHA</th>
              </tr>
            </thead>
            <tbody>
              {adminsUsers && adminsUsers.map((user) => (
                <tr key={user._id}>
                  <td>{user.login}</td>
                  <td>
                    <Button
                      className={"btn-step btn-edit-table"}
                      color="primary"
                      autoFocus
                      style={{ height: 45 }}
                      onClick={() => handleOpenModalPermissions({ user: user })}
                    >
                      Permissões
                    </Button>
                  </td>
                  <td>
                    <Button
                      className={"btn-step btn-edit-table"}
                      color="primary"
                      autoFocus
                      style={{ height: 45 }}
                      onClick={() => handleOpenModalChangePassword({ user: user })}
                    >
                      Alterar senha
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </main>

      <ModalDialog
        dialogTitle="Permissões"
        className="_modal-category GridProducts"
        maxWidth="md"
        ref={refModalPermissions}
      >
        <Accordion title="Lojinhas">
          {userPermissions && allAccess.scamb.map(access => (
            <div className="accordion-access">
              <div style={{ display: 'flex' }}>
                <ListItemIcon>
                      {scambMenu[access]?.icon}
                  </ListItemIcon>
                <p style={{marginTop: '3px'}}>{scambMenu[access]?.text}</p>
              </div>
              <Switch
                color="primary"
                checked={handleCheck({ menu: 'scamb', access: access })} 
                onChange={() => handleChangeCheck({ menu: 'scamb', access: access })}
              />
            </div>
          ))} 
        </Accordion>

        <Accordion title="Shopping">
          {userPermissions && allAccess.shopping.map(access => (
            <div className="accordion-access">
              <div style={{ display: 'flex' }}>
                <ListItemIcon>
                      {shoppingMenu[access]?.icon}
                  </ListItemIcon>
                <p style={{marginTop: '3px'}}>{shoppingMenu[access]?.text}</p>
              </div>
              <Switch
                color="primary"
                checked={handleCheck({ menu: 'shopping', access: access })}
                onChange={() => handleChangeCheck({ menu: 'shopping', access: access })}
              />
            </div>
          ))}
        </Accordion>

        <Accordion title="PDV">
          {userPermissions && allAccess.pdv.map(access => (
            <div className="accordion-access">
              <div style={{ display: 'flex' }}>
                <ListItemIcon>
                      {pdvMenu[access]?.icon}
                  </ListItemIcon>
                <p style={{marginTop: '3px'}}>{pdvMenu[access]?.text}</p>
              </div>
              <Switch
                color="primary"
                checked={handleCheck({ menu: 'pdv', access: access })}
                onChange={() => handleChangeCheck({ menu: 'pdv', access: access })}
              />
            </div>
          ))}
        </Accordion>
        <Button
          className="btn-step"
          style={{ height: 35, marginBottom: '20px' }}
          onClick={() => handleUpdateAdminUser({ permissoes: userPermissions }) }
        >
          Aplicar
        </Button>
      </ModalDialog>

      <ModalDialog
        dialogTitle="Criar Novo Usuário"
        className="_modal-category GridProducts"
        maxWidth="sm"
        ref={refModalCreateUser}
      >
        <div className="select-product-container">
          <div className="search-products-container">
            <div className="_wrap-form" style={{ marginBottom: '20px', marginTop: '20px' }}>
              <form className="_form" onSubmit={handleSubmit(handleCreateAdminUser)}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      label="Login *"
                      type="text"
                      variant="outlined"
                      size={"small"}
                      name="login"
                      fullWidth
                      helperText={!!errors.login && "Campo não pode ficar em branco"}
                      error={!!errors.login}
                      inputRef={register({ required: true })}
                      inputProps={{ maxLength: 40 }}
                      InputLabelProps={{ shrink: true}}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      label="Senha *"
                      variant="outlined"
                      size={"small"}
                      inputRef={register({ required: true })}
                      inputProps={{ maxLength: 40 }}
                      InputLabelProps={{ shrink: true }}
                      type={password ? "text" : "password"}
                      name="senha"
                      error={!!errors.senha}
                      helperText={!!errors.login && "Campo não pode ficar em branco"}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setPassword(!password)}
                              edge="end"
                            >
                              <FontAwesomeIcon
                                icon={password ? faEye : faEyeSlash}
                              ></FontAwesomeIcon>
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <h1 className="_content-title">Permissões</h1>
                    <Accordion title="Lojinhas">
                      {allAccess.scamb.map(access => (
                        <div className="accordion-access">
                          <div style={{ display: 'flex' }}>
                            <ListItemIcon>
                                  {scambMenu[access]?.icon}
                              </ListItemIcon>
                            <p style={{marginTop: '3px'}}>{scambMenu[access]?.text}</p>
                          </div>
                          <Switch
                            color="primary"
                            checked={handleCheck({ menu: 'scamb', access: access })} 
                            onChange={() => handleChangeCheck({ menu: 'scamb', access: access })}
                          />
                        </div>
                      ))} 
                    </Accordion>

                    <Accordion title="Shopping">
                      {allAccess.shopping.map(access => (
                        <div className="accordion-access">
                          <div style={{ display: 'flex' }}>
                            <ListItemIcon>
                                  {shoppingMenu[access]?.icon}
                              </ListItemIcon>
                            <p style={{marginTop: '3px'}}>{shoppingMenu[access]?.text}</p>
                          </div>
                          <Switch
                            color="primary"
                            checked={handleCheck({ menu: 'shopping', access: access })}
                            onChange={() => handleChangeCheck({ menu: 'shopping', access: access })}
                          />
                        </div>
                      ))}
                    </Accordion>

                    <Accordion title="PDV">
                      {allAccess.pdv.map(access => (
                        <div className="accordion-access">
                          <div style={{ display: 'flex' }}>
                            <ListItemIcon>
                                  {pdvMenu[access]?.icon}
                              </ListItemIcon>
                            <p style={{marginTop: '3px'}}>{pdvMenu[access]?.text}</p>
                          </div>
                          <Switch
                            color="primary"
                            checked={handleCheck({ menu: 'pdv', access: access })}
                            onChange={() => handleChangeCheck({ menu: 'pdv', access: access })}
                          />
                        </div>
                      ))}
                    </Accordion>
                  </Grid>
                </Grid>

                <Button
                  type="submit"
                  className="btn-step"
                  style={{ height: 35, marginBottom: '20px' }}
                >
                  Concluir
                </Button>
              </form>
            </div>
          </div>
        </div>
      </ModalDialog>

      <ModalDialog
        dialogTitle="Alterar Senha do Usuário"
        className="_modal-category GridProducts"
        maxWidth="sm"
        ref={refModalChangePassword}
      >
        <div className="select-product-container">
          <div className="search-products-container">
            <div className="_wrap-form" style={{ marginBottom: '20px', marginTop: '20px' }}>
              <form className="_form" onSubmit={handleSumitNewPassword(handleChangePassword)}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      label="Senha *"
                      variant="outlined"
                      size={"small"}
                      inputRef={registerNewPassword({ required: true })}
                      inputProps={{ maxLength: 40 }}
                      InputLabelProps={{ shrink: true}}
                      type={password ? "text" : "password"}
                      name="senha"
                      error={!!errorsNewPassword.senha}
                      helperText={!!errorsNewPassword.login && "Campo não pode ficar em branco"}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setPassword(!password)}
                              edge="end"
                            >
                              <FontAwesomeIcon
                                icon={password ? faEye : faEyeSlash}
                              ></FontAwesomeIcon>
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>

                <Button
                  type="submit"
                  className="btn-step"
                  style={{ height: 35, marginBottom: '20px' }}
                >
                  Concluir
                </Button>
              </form>
            </div>
          </div>
        </div>
      </ModalDialog>
    </>
  )
}

export default Access;