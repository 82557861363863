import styled from "styled-components";

export const Product = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    padding: 15px 0;
`;

export const Column = styled.div`
    button {
        box-shadow: none;
        height: 30px;
        width: 30px;
        min-width: 30px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            height: 16px;
            width: auto !important;

            path {
                fill: ${({ theme }) => theme.color.red}
            }
        }
    }

    &:first-child {
        display: flex;
        align-items: flex-start;
    }

    &:last-child {
        margin-left: 0.625rem;
    }
`;

export const ProductImage = styled.div`
    height: 38px;
    width: 38px;
    min-width: 38px;
    overflow: hidden;
    border: 1px solid ${({ theme }) => theme.color.extraLight};
    border-radius: 0.25rem;
`;

export const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: 0;
    max-width: 100%;
    display: inline-block;
    vertical-align: middle;
`;

export const Content = styled.div`
    margin-left: 10px;
`;

export const Title = styled.div`
    font-size: 0.75rem;
    font-weight: ${({ theme }) => theme.fontWeight.fw700};
    color: ${({ theme }) => theme.color.black};
    margin-bottom: 0.375rem;
`;

export const Equivalent = styled.div`
    font-size: 0.625rem;
    color: ${({ theme }) => theme.color.gray};
    font-weight: ${({ theme }) => theme.fontWeight.fw700};
    display: flex;
    align-items: center;
`;

export const RealPrice = styled.div`
    color: ${({ theme }) => theme.color.grayBold};
    font-size: 0.875rem;
    margin-left: 0.25rem;
`;
