import ShoppingCart from "@material-ui/icons/ShoppingCart";
import SubjectIcon from "@material-ui/icons/Subject";
import AssignmentInd from "@material-ui/icons/AssignmentInd";
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import LinkIcon from '@material-ui/icons/Link';
import ContactsIcon from '@material-ui/icons/Contacts';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import Accounts from '../views/scamb/Accounts';
import Scambers from '../views/scamb/Scambers';
import Orders from '../views/scamb/Orders';
import Showcase from '../views/scamb/Showcase';
import ReversalOrders from '../views/scamb/ReversalOrders';
import Anuncios from '../views/scamb/Anuncios';
import AnunciosByBrands from '../views/scamb/AnunciosByBrands';
import OrderPoints from '../views/scamb/OrderPoints';
import ListCategories from '../views/scamb/ListCategories';
import QuickLink from '../views/scamb/QuickLink';
import AdExternal from '../views/scamb/AdExternal';
import Curatorship from '../views/scamb/Curatorship';
import Coupons from '../views/scamb/Coupons';
import TransferAd from '../views/scamb/TransferAd';
import ScamberPartnerShopping from '../views/shopping/Accounts';
import OrdersShopping from '../views/shopping/Orders';
import ShowcaseShopping from '../views/shopping/Showcase';
import QuickLinkShopping from '../views/shopping/QuickLink';
import PartnerBannerShopping from '../views/shopping/PartnerBanner';
import AnunciosShopping from '../views/shopping/Anuncios';
import AnunciosByBrandShopping from '../views/shopping/AnunciosByBrand';
import ListCategoriesShopping from '../views/shopping/ListCategories';
import ReversalOrdersShopping from '../views/shopping/ReversalOrders';
import AdExternalShopping from '../views/shopping/AdExternal';
import CheckoutPDV from '../views/pdv/checkout';
import OrderPDV from '../views/pdv/orders';

export const allAccess = {
  scamb: [
    "accounts",
    "scambers",
    "scambersRead",
    "orders",
    "showcase",
    "reversalOrders",
    "orderPoints",
    "quickLinks",
    "ad",
    "adBrands",
    "adCategory",
    "adExternal",
    "curatorship",
    "coupons",
    "adTransfer"
  ],
  shopping: [
    "accounts",
    "orders",
    "showcase",
    "quickLinks",
    "partnerbanner",
    "reversalOrders",
    "ad",
    "adBrands",
    "adCategory",
    "adExternal"
  ],
  pdv: [
    "checkout",
    "orders"
  ]
}

export const scambMenu = {
  'accounts': { 
    link: '/contas_detalhe', 
    icon: <SubjectIcon />,
    text: 'Contas detalhe',
  },
  'scambers': { 
    link: '/scambers', 
    icon: <AssignmentInd />,
    text: 'Scambers',
  },
  'scambersRead': { 
    link: '/scambers', 
    icon: <AssignmentInd />,
    text: 'Scambers (Leitura)',
  },
  'orders': { 
    link: '/pedidos', 
    icon: <ShoppingCart />,
    text: 'Pedidos',
  },
  'showcase': { 
    link: '/vitrine', 
    icon: <ViewCarouselIcon />,
    text: 'Vitrine',
  },
  'reversalOrders': { 
    link: '/pedidos_devolucao', 
    icon: <SettingsBackupRestoreIcon />,
    text: 'Devolução de Pedidos',
  },
  'orderPoints': { 
    link: '/orderpoints', 
    icon: <MonetizationOnIcon />,
    text: 'Venda de pontos',
  },
  'quickLinks': { 
    link: '/liskrapido', 
    icon: <LinkIcon />,
    text: 'Links Rápidos',
  },
  'ad': { 
    link: '/anuncios', 
    icon: <LocalOfferIcon />,
    text: 'Categorias',
  },
  'adBrands': { 
    link: '/anuncios/marcas', 
    icon: <LocalOfferIcon />,
    text: 'Produtos Cadastrados por Marca',
  },
  'adCategory': { 
    link: '/categorias', 
    icon: <LocalOfferIcon />,
    text: 'Subcategorias',
  },
  'adExternal': { 
    link: '/anuncioexterno', 
    icon: <PlaylistAddCheckIcon />,
    text: 'Anúncios Externos',
  },
  'curatorship': { 
    link: '/curadoria', 
    icon: <AssignmentTurnedInIcon />,
    text: 'Curadoria',
  },
  'coupons': {
    link: '/cupons',
    icon: <AttachMoneyIcon />,
    text: 'Cupons',
  },
  'adTransfer': {
    link: '/anuncios/transferencias',
    icon: <FileCopyIcon />,
    text: 'Transferências',
  }
  
}

export const shoppingMenu = {
  'accounts': { 
    link: '/shopping/parceiros', 
    icon: <ContactsIcon />,
    text: 'Parceiros',
  },    
  'orders': { 
    link: '/shopping/pedidos', 
    icon: <ShoppingCart />,
    text: 'Pedidos',
  },
  'showcase': { 
    link: '/shopping/vitrine', 
    icon: <ViewCarouselIcon />,
    text: 'Vitrine',
  },
  'reversalOrders': { 
    link: '/shopping/pedidos_devolucao', 
    icon: <SettingsBackupRestoreIcon />,
    text: 'Devolução de Pedidos',
  },
  'partnerbanner': { 
    link: '/shopping/bannerparceiro', 
    icon: <PhotoLibraryIcon />,
    text: 'Banners dos Parceiros',
  },
  'quickLinks': { 
    link: '/shopping/liskrapido', 
    icon: <LinkIcon />,
    text: 'Links Rápidos',
  },
  'ad': { 
    link: '/shopping/anuncios', 
    icon: <LocalOfferIcon />,
    text: 'Categorias',
  },
  'adBrands': { 
    link: '/shopping/anuncios/marcas', 
    icon: <LocalOfferIcon />,
    text: 'Produtos Cadastrados por Marca',
  },
  'adCategory': { 
    link: '/shopping/categorias', 
    icon: <LocalOfferIcon />,
    text: 'Subcategorias',
  },
  'adExternal': { 
    link: '/shopping/anuncioexterno', 
    icon: <PlaylistAddCheckIcon />,
    text: 'Anúncios Externos',
  },
}

export const pdvMenu = {
  'orders': { 
    link: '/pdv/pedidos', 
    icon: <ShoppingCart />,
    text: 'Pedidos',
  },
  'checkout': { 
    link: '/pdv/checkout', 
    icon: <ShoppingCart />,
    text: 'Checkout',
  },    
}