import Service from './Service';

class OrderShopping extends Service {

    constructor() {
        super();
    }

    orderFinish({ order, scamber }) {
        return this.axiosInstance.post(`/ordershopping/finish?orderId=${order}&scamber=${scamber}`);
    }
}

export default OrderShopping;