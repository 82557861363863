import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import GroupAddIcon from '@material-ui/icons/GroupAdd';

import Authentication from './../services/Authentication';
import { AccordionInside } from './../components/ui/accordion'
import { scambMenu, shoppingMenu, pdvMenu } from '../data/Access'
import Events from './../services/Events';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "white",
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginRight: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    // marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "space-between",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

export default function PersistentDrawerLeft() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [permissions, setPermissions] = React.useState(false);
  const [type, setType] = React.useState(false);
  const [userLogin, setUserLogin] = React.useState();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const doLogout = (e) => {
    const auth = new Authentication();

    auth.doLogout().then(() => {
      e.preventDefault();
      localStorage.removeItem("token");
      setPermissions(false);
      setType(false);
      window.location.href = "/";
    });
  };

  useEffect(() => {
    Events.subscribe('loadedPermissions', (payload) => {
      setPermissions(payload.permissions);
      setType(payload.type);
      setUserLogin(payload.login);
    });

  }, []);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <Typography variant="h6" noWrap>
            <img src="/images/logo.png" alt="Scamb" />
          </Typography>

          
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            {userLogin && (
              <Typography variant="body1" style={{ color: '#000', marginRight: '20px'}}>
                {String(userLogin)}
              </Typography>
            )}
            {permissions && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, open && classes.hide)}
              > 
                <MenuIcon />
              </IconButton>
            )}
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <div style={{ textAlign: "left" }}>Menu</div>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        

            
        {permissions.scamb && permissions.scamb.length > 0 && (
          <AccordionInside title="Lojinha">
            <List>
              {permissions.scamb.map(permission => (
                <Link to={scambMenu[permission]?.link}>
                  <ListItem button>
                    <ListItemIcon>
                      {scambMenu[permission]?.icon}
                    </ListItemIcon>
                    <ListItemText primary={scambMenu[permission]?.text} />
                  </ListItem>
                </Link>
              ))
              }
            </List>
          </AccordionInside>
        )}

        {permissions.shopping && permissions.shopping.length > 0 && (
          <AccordionInside title="Shopping">
            <List>
              {permissions.shopping.map(permission => (
                <Link to={shoppingMenu[permission]?.link}>
                  <ListItem button>
                    <ListItemIcon>
                      {shoppingMenu[permission]?.icon}
                    </ListItemIcon>
                    <ListItemText primary={shoppingMenu[permission]?.text} />
                  </ListItem>
                </Link>
              ))
              }
            </List>
          </AccordionInside>
        )}

        {permissions.pdv && permissions.pdv.length > 0 && (
          <AccordionInside title="PDV">
            <List>
              {permissions.pdv.map(permission => (
                <Link to={pdvMenu[permission]?.link}>
                  <ListItem button>
                    <ListItemIcon>
                      {pdvMenu[permission]?.icon}
                    </ListItemIcon>
                    <ListItemText primary={pdvMenu[permission]?.text} />
                  </ListItem>
                </Link>
              ))
              }
            </List>
          </AccordionInside>
        )}

        {type === 'admin' && (
          <List>
              <Link to="/acessos">
                <ListItem button>
                  <ListItemIcon>
                    <GroupAddIcon />
                  </ListItemIcon>
                  <ListItemText primary="Acessos" />
                </ListItem>
              </Link>
          </List>
        )}

        <Divider />
        <List>

          <ListItem button onClick={(e) => doLogout(e)}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Sair" />
          </ListItem>

        </List>
      </Drawer>
    </div>
  );
}
