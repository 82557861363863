import React, { Component } from 'react';
import { Switch, Route, withRouter, Redirect } from 'react-router-dom';
import Swal from 'sweetalert2';

// Views
import home from './views/home';
import NotFound404 from './views/NotFound404';
import contas_detalhe from './views/scamb/Accounts';
import Scambers from './views/scamb/Scambers';
import Orders from './views/scamb/Orders';
import Showcase from './views/scamb/Showcase';
import ReversalOrders from './views/scamb/ReversalOrders';
import Anuncios from './views/scamb/Anuncios';
import AnunciosByBrands from './views/scamb/AnunciosByBrands';
import OrderPoints from './views/scamb/OrderPoints';
import ListCategories from './views/scamb/ListCategories';
import QuickLink from './views/scamb/QuickLink';
import AdExternal from './views/scamb/AdExternal';
import Curatorship from './views/scamb/Curatorship';
import Coupons from './views/scamb/Coupons';
import TransferAd from './views/scamb/TransferAd';
import ScamberPartnerShopping from './views/shopping/Accounts';
import OrdersShopping from './views/shopping/Orders';
import ShowcaseShopping from './views/shopping/Showcase';
import QuickLinkShopping from './views/shopping/QuickLink';
import PartnerBannerShopping from './views/shopping/PartnerBanner';
import AnunciosShopping from './views/shopping/Anuncios';
import AnunciosByBrandShopping from './views/shopping/AnunciosByBrand';
import ListCategoriesShopping from './views/shopping/ListCategories';
import ReversalOrdersShopping from './views/shopping/ReversalOrders';
import AdExternalShopping from './views/shopping/AdExternal';
import CheckoutPDV from './views/pdv/checkout';
import OrderPDV from './views/pdv/orders';
import Access from './views/Access';


import Scamber from './services/Scamber';
import Events from './services/Events';
import { scambMenu, shoppingMenu, pdvMenu } from './data/Access'


class Routes extends Component {
     constructor() {
         super();

         this.state = {
            permissionsLoaded: false,
            permissions: {
                scamb: [],
                shopping: [],
                pdv: [],
            },
            adminUser: false,
         }
     }

    componentDidMount() {
        if (!this.state.permissionsLoaded) {
            const scamber = new Scamber();

            scamber.loadScamberPermissions().then((response) => {
                if (response.data.type === 'success') {
                    const data = response.data.payload.permissions;
                    const permissions = {
                        scamb: [],
                        shopping: [],
                        pdv: [],
                    };
                    let adminUser = false;

                    Events.dispatch('loadedPermissions', response.data.payload)

                    adminUser = response.data.payload.type ? true : false;
                    data.scamb && data.scamb.length !== 0 && (permissions['scamb'] = data.scamb);   
                    data.shopping && data.shopping.length !== 0 && (permissions['shopping'] = data.shopping);
                    data.pdv && data.pdv.length !== 0 && (permissions['pdv'] = data.pdv);
                    
                    const scambPermision = permissions.scamb.find(permission => this.props.location.pathname.indexOf(scambMenu[permission].link) >= 0);
                    const shoppingPermision = permissions.shopping.find(permission => this.props.location.pathname.indexOf(shoppingMenu[permission].link) >= 0);
                    const pdvPermision = permissions.pdv.find(permission => this.props.location.pathname.indexOf(pdvMenu[permission].link) >= 0);

                    this.setState({permissionsLoaded: true})
    
                    if(!adminUser && !scambPermision && !shoppingPermision && !pdvPermision && (this.props.location.pathname !== '/' && this.props.location.pathname !== '/notfound')) {
                        this.props.history.push('/notfound');
                    }
                }            
            }).catch((err) => {

            });

        }
    }

    componentDidUpdate(prevProps) {
        if ((this.props.location !== prevProps.location) || !this.state.permissionsLoaded) {
            const scamber = new Scamber();

            scamber.loadScamberPermissions().then((response) => {
                if (response.data.type === 'success') {
                    const data = response.data.payload.permissions;
                    const permissions = {
                        scamb: [],
                        shopping: [],
                        pdv: [],
                    };
                    let adminUser = false;

                    Events.dispatch('loadedPermissions', response.data.payload)

                    adminUser = response.data.payload.type ? true : false;
                    data.scamb && data.scamb.length !== 0 && (permissions['scamb'] = data.scamb);   
                    data.shopping && data.shopping.length !== 0 && (permissions['shopping'] = data.shopping);
                    data.pdv && data.pdv.length !== 0 && (permissions['pdv'] = data.pdv);
                    
                    const scambPermision = permissions.scamb.find(permission => this.props.location.pathname.indexOf(scambMenu[permission].link) >= 0);
                    const shoppingPermision = permissions.shopping.find(permission => this.props.location.pathname.indexOf(shoppingMenu[permission].link) >= 0);
                    const pdvPermision = permissions.pdv.find(permission => this.props.location.pathname.indexOf(pdvMenu[permission].link) >= 0);
    
                    this.setState({permissionsLoaded: true})
    
                    if(!adminUser && !scambPermision && !shoppingPermision && !pdvPermision && (this.props.location.pathname !== '/' && this.props.location.pathname !== '/notfound')) {
                        this.props.history.push('/notfound');
                    }
                }            
            });

        }
       
    } 

    render() {
        return (

            <Switch>
                {/* Páginas deslogadas */}
                <Route exact path="/" component={home} />

                {/* Páginas logadas */}
                <PrivateRoute exact path="/contas_detalhe" component={contas_detalhe} />
                <PrivateRoute exact path="/scambers" component={Scambers} />
                <PrivateRoute exact path="/pedidos" component={Orders} />
                <PrivateRoute exact path="/vitrine" component={Showcase} />
                <PrivateRoute exact path="/pedidos_devolucao" component={ReversalOrders} />
                <PrivateRoute exact path="/anuncios" component={Anuncios} />
                <PrivateRoute exact path="/orderpoints" component={OrderPoints} />
                <PrivateRoute exact path="/anuncios/marcas" component={AnunciosByBrands} />
                <PrivateRoute exact path="/categorias" component={ListCategories} />
                <PrivateRoute exact path="/liskrapido" component={QuickLink} />
                <PrivateRoute exact path="/anuncioexterno" component={AdExternal} />
                <PrivateRoute exact path="/curadoria" component={Curatorship} />
                <PrivateRoute exact path="/cupons" component={Coupons} />
                <PrivateRoute exact path="/anuncios/transferencias" component={TransferAd} />
                <PrivateRoute exact path="/shopping/parceiros" component={ScamberPartnerShopping} />
                <PrivateRoute exact path="/shopping/pedidos" component={OrdersShopping} />
                <PrivateRoute exact path="/shopping/vitrine" component={ShowcaseShopping} />
                <PrivateRoute exact path="/shopping/liskrapido" component={QuickLinkShopping} />
                <PrivateRoute exact path="/shopping/bannerparceiro" component={PartnerBannerShopping} />
                <PrivateRoute exact path="/shopping/anuncios" component={AnunciosShopping} />
                <PrivateRoute exact path="/shopping/anuncios/marcas" component={AnunciosByBrandShopping} />
                <PrivateRoute exact path="/shopping/categorias" component={ListCategoriesShopping} />
                <PrivateRoute exact path="/shopping/pedidos_devolucao" component={ReversalOrdersShopping} />
                <PrivateRoute exact path="/shopping/anuncioexterno" component={AdExternalShopping} />
                <PrivateRoute exact path="/pdv/checkout" component={CheckoutPDV} />
                <PrivateRoute exact path="/pdv/pedidos" component={OrderPDV} />
                <PrivateRoute exact path="/acessos" component={Access} />

                <Route exact path="/notfound" component={NotFound404} />

            </Switch>

        )

    }
}

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => localStorage.getItem('token') !== null ?
        (<Component {...props} />) : (<Redirect to={{ pathname: "/" }} />)}
    />
);


export default withRouter(Routes);