import styled from "styled-components";

export const WrapperPage = styled.div`
    padding-top: 4.75rem;
    margin-left: 0.9375rem;
    margin-right: 0.9375rem;
`;

export const WrappLoadMore = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 20px 0;
`;

export const WrapperHead = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
`;

export const PageHead = styled.div``;

export const PageTitle = styled.h1`
    font-size: 0.875rem;
    color: ${({ theme }) => theme.color.black};
    font-weight: ${({ theme }) => theme.fontWeight.fw700};
    margin-bottom: 0.375rem;
    `;

export const PageSubtitle = styled.h2`
    font-size: 0.625rem;
    color: ${({ theme }) => theme.color.gray};
    font-weight: ${({ theme }) => theme.fontWeight.fw700};
    margin-bottom: 0;
`;

export const PageHeadActions = styled.div`

    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
        font-size: 0.75rem;
        font-weight: ${props => props.theme.fontWeight.fw700};
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: none;
        margin-right: 15px;

        svg {
            width: auto !important;
            margin-right: 0.375rem;
        }

        &:first-child {
            min-width: 113px;
            svg {
                path {
                    fill: ${({ theme }) => theme.color.white};
                }
                
                height: 14px;
            }
        }

        &:last-child {
            svg {
                height: 15px;
            }
            margin-right: 0px;
        }
    }
`;

// * Drawer
export const WrapApplyFilter = styled.div`
    padding: 15px 0;
    margin-left: 15px;
    margin-right: 15px;
`;

// * Drawer Shopping Cart
export const DrawerHead = styled.div`
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 64px;
    border-bottom: 0.0625rem solid ${({ theme }) => theme.color.light};
`;

export const DColumn = styled.div`
    button {
        box-shadow: none;
        height: 38px;
        width: 38px;
        min-width: 38px;
        border-radius: 100px;

        svg {
            height: 16px;
            width: auto !important;
            position: relative;
            top: 2px;

            path {
                fill: ${({ theme }) => theme.color.black}
            }
        }
    }
`;

export const DrawerTitle = styled.div`
    
    font-weight: ${({ theme }) => theme.fontWeight.fw700};
    font-size: 14px;
    color: ${({ theme }) => theme.color.black};
    
    svg {
        height: 18px;
        width: auto !important;
        margin-right: 0.375rem;
        
        path {
            fill: ${({ theme }) => theme.color.black}
        }
    }
`;


export const DrawerContent = styled.div`

    .ProductInlineBox {
        padding-left: 15px;
        padding-right: 15px;
        border-bottom: 1px solid ${({ theme }) => theme.color.light};
    }

    ul {

        .ListBlockItem {
            padding: 0.625rem 0.9375rem;
            border-bottom: 0.0625rem dashed ${({ theme }) => theme.color.light};
            background-color: #fafafa;
        }
    }
`;

// * Drawer Filters
export const FormFilters = styled.form`
    padding: 15px 15px;

    .MuiGrid-container {

        .MuiGrid-root.MuiGrid-item {

            button {
                box-shadow: none;
            }
        }
    }
`;

// * Form Dialog
export const FormCheckout = styled.form`
    padding-bottom: 6.25rem;
`;

export const FormTryAgainNF = styled.form`
    /* padding-bottom: 6.25rem; */
`;

export const WrapperFormConfirmAccount = styled.div`
    padding: 3.75rem 0 1.875rem 0;
    /* max-width: 452px; */
    margin: 0 auto;
`;

export const FormTitle = styled.h1`
    text-align: center;
    font-size: 16px;
    font-weight: ${({ theme }) => theme.fontWeight.fw700};
    color: ${({ theme }) => theme.color.black};
    font-family: ${({ theme }) => theme.font.comfortaa};
`;

export const FormConfirmAccount = styled.form`
    margin: 0.9375rem 0;
`;

export const FormTextField = styled.input`
    width: 100%;
    border-width: 0.0625rem;
    border-style: solid;
    border-color: ${props => props.error ? ({ theme }) => theme.color.red : ({ theme }) => theme.color.light};
    text-align: ${props => props.textAlign ? props.textAlign : "left"};
    padding: 0.625rem 0.9375rem;
    border-radius: 0.1875rem;
`;

export const WrapContinueButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    padding-left: 15px;
    padding-right: 15px;
    background: ${({theme}) => theme.color.white};
    height: 70px;
    border-top: 1px solid ${({theme}) => theme.color.light};
    -webkit-box-shadow: 0 -4px 8px 4px rgba(100,100,100,0.2);
    box-shadow: 0 -4px 8px 4px rgba(100,100,100,0.2);
    z-index: 99;

    button {
        display: flex;
        align-items: center;
        justify-content: center;
        /* margin: 0 auto; */
        padding: 0.719rem 1.264rem;
        box-shadow: none;

        &:last-child {
            
            svg {
                margin-left: 8px;
    
                path {
                    fill: ${({ theme }) => theme.color.white};
                }
            }
        }
        &:first-child {

            svg {
                margin-right: 8px;
    
                path {
                    fill: ${({ theme }) => theme.color.gray};
                }
            }
        }
    }
`;

// * Checkout Dialog
export const WrapCheckoutButton = styled.div`
    text-align: center;
    padding-top: 0.9375rem;
    padding-bottom: 0.9375rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    padding-left: 15px;
    padding-right: 15px;
    background: ${({theme}) => theme.color.white};
    height: 70px;
    border-top: 1px solid ${({theme}) => theme.color.light};
    -webkit-box-shadow: 0 -4px 8px 4px rgba(100,100,100,0.2);
    box-shadow: 0 -4px 8px 4px rgba(100,100,100,0.2);
    z-index: 99;

    button {
        box-shadow: none;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: ${({ theme }) => theme.font.nunito};
        padding: 0.719rem 1.264rem;

        &:last-child {
            
            svg {
                margin-left: 8px;
    
                path {
                    fill: ${({ theme }) => theme.color.white};
                }
            }
        }
        &:first-child {

            svg {
                margin-right: 8px;
    
                path {
                    fill: ${({ theme }) => theme.color.gray};
                }
            }
        }

        &:disabled {
            svg {
    
                path {
                    fill: ${({ theme }) => theme.color.gray};
                    opacity: 0.4;
                }
            }
        }

    }
`;

export const CheckoutCardStyled = styled.div`
    width: 100%;
    border: 0.0625rem solid ${({ theme }) => theme.color.light};
    border-radius: 0.1875rem;
    margin-bottom: 0.9375rem;
    background-color: ${({ theme }) => theme.color.white};

    .ListMarkup.Checkout {
        
        li {
            display: flex;
            align-items: center;
            justify-content: space-between;

            div {
                .discount {

                    span {
                        color: #C2403F;
                    }
                }
            }
        }
    }
`;

export const CheckoutCardHead = styled.div`
    padding: 0.9375rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

export const CardIcon = styled.div`
    svg {
        height: 16px;
        width: auto !important;

        path {

            fill: ${({ theme }) => theme.color.gray};
        }
    }
`;

export const CardTitle = styled.div`
    font-family: ${({ theme }) => theme.font.nunito};
    color: ${({ theme }) => theme.color.black};
    font-weight: ${({ theme }) => theme.fontWeight.fw700};
    font-size: 0.9375rem;
    margin-left: 0.625rem;
`;

export const CheckoutCardContent = styled.div`
    /* padding: 0 0.9375rem 1.25rem 0.9375rem; */
    
    .ListMarkup {
        /* padding: 0 0.9375rem 1.25rem 0.9375rem; */

        li {
            padding: 10px 15px;
            font-family: ${({ theme }) => theme.font.comfortaa};
            border-bottom: 1px dashed ${({ theme }) => theme.color.light};
        }

        li.user-points {
            border: 1px dashed ${({ theme }) => theme.color.blue};

            div {
                color: ${({ theme }) => theme.color.blue};
            }
        }
    }
`;

export const CustomError = styled.div`
    font-family: ${({ theme }) => theme.font.nunito};
    color: ${({ theme }) => theme.color.red};
    font-weight: ${({ theme }) => theme.fontWeight.fw700};
    font-size: 0.6875rem;
    position: relative;
    top: 1px;
    left: 3px;
    text-align: left;

    svg {
        transform: rotate(90deg);
        margin-right: 0.125rem;

        path {
            fill: ${({ theme }) => theme.color.red};
        }
    }
`;

export const WrapperContentColumn = styled.label`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: relative;
    padding: 0.9375rem 0.9375rem;
    border-radius: 0.1875rem;
    overflow: hidden;
    cursor: pointer;

    .CheckMark {
        height: 20px;
        width: 20px;
        min-width: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0.0625rem solid ${({ theme }) => theme.color.light};
        border-radius: 100px;
        background-color: ${({ theme }) => theme.color.white};
        position: absolute;
        top: 0;
        bottom: 0;
        right: 15px;
        margin-top: auto;
        margin-bottom: auto;
        z-index: 2;

        svg {
            height: 10px;
            width: auto !important;

            path {
                fill: ${({ theme }) => theme.color.gray};
                opacity: 0.4;
            }
        }
    }

    input:checked ~ .CheckMark {
        background-color: rgba(255,255,255, 0.1);
        border: 0.0625rem solid rgba(255,255,255, 0);
        
        svg {
            
            path {
                fill: ${({ theme }) => theme.color.white};
                opacity: 1;
            }
        }
    }

    input:checked ~ .Overlay {
        background-color: ${({ theme }) => theme.color.blue};
    }
    
    input:checked ~ .ContentColumn {
        div {
            color: ${({ theme }) => theme.color.white};

            svg {
            
                path {
                    fill: ${({ theme }) => theme.color.white};
                    opacity: 1;
                }
            }
        }
    }

`;

export const ContentColumn = styled.div`
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        z-index: 2;
`;

export const PaymentOptionStyled = styled.div`
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
`;

export const InputRadio = styled.input`
    display: none;
    opacity: 0;
    visibility: hidden;
`;

export const PaymentTypeIcon = styled.div`
    position: relative;
    top: 1px;

    svg {
        height: 16px;
        width: auto !important;

        path {
            fill: ${({ theme }) => theme.color.gray};
            opacity: 1;
        }
    }
`;

export const PaymentOptionTitle = styled.div`
    margin-left: 0.625rem;
`;

export const CheckMark = styled.div``;

export const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.color.white};
    z-index: 1;
    border: 1px solid ${({ theme }) => theme.color.light};
    border-radius: 0.1875rem;
`;

// * Checkout Products

export const WrapProducts = styled.div`
        border-top: 1px solid ${({ theme }) => theme.color.light};
        
    .ProductInlineBox {
        font-family: ${({ theme }) => theme.font.comfortaa};
        border-bottom: 1px solid ${({ theme }) => theme.color.light};
        padding-left: 0.9375rem;
        padding-right: 0.9375rem;

        .Column {

            .ProductInlineImage {
                height: 75px;
                width: 75px;
                min-width: 75px;
            }
        }
    }
`;

export const CupomBlock = styled.div`
    display: flex;
    width: 100%;
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
    padding-bottom: 1.25rem;
`;

export const FieldWrapper = styled.div`
    display: flex;
    width: 100%;

    .MuiFormControl-fullWidth {
        width: 100%;
    }

    button {
        width: 80px;
        margin-left: 10px;
        background-color: #14233E;
        color: #FFFFFF;
    }
`;

export const CheckoutCardWrapperContent = styled.div`
    padding-bottom: 1.25rem;
`

export const WrapperFormPersonalData = styled.div`
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
    padding-bottom: 1.25rem;
`

export const CountCartItens = styled.span`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 16px;
    width: 16px;
    min-width: 16px;
    background-color: ${({ theme }) => theme.color.blue};
    color: ${({ theme }) => theme.color.white};
    font-size: 0.625rem;
    border-radius: 100px;
    margin-left: 0.3125rem;
`;

export const WrapExternalLink = styled.div`
    width: 100%;
    border: 0.0625rem solid ${({ theme }) => theme.color.light};
    border-radius: 0.1875rem;
    background-color: ${({ theme }) => theme.color.white};
    padding: 5px 10px;
    max-width: 240px;
    margin: 0 auto;
    margin-bottom: 0.9375rem;
    background-color: #f9f9f9;

    a {
        color: ${({ theme }) => theme.color.blue} !important;
        display: inline-block;
        
        &:visited,
        &:active,
        &:focus {
            color: ${({ theme }) => theme.color.red};
        }
    }
`;

export const WrapDiscountValue = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    .minus {
        font-size: 1.125rem;
        color: #C2403F;
        font-weight: ${({ theme }) => theme.fontWeight.fw700};
        margin-right: 0.125rem;
        display: inline-block;
    }
`;